import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import APIF from "../../apiF"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Container,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
//lang
import { withTranslation } from "react-i18next"
import FileDownload from "js-file-download"
import { MetaTags } from "react-meta-tags"

const DataTableAppOffre = props => {
  var userAuth = JSON.parse(localStorage.getItem("userAuthEntreprise"))
  var entrepriseId = userAuth.user.id_entreprise
  const [loading, setLoading] = useState(false)
  // Data Table
  const [appOffre, setAppOffre] = useState([])
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: appOffre.length,
    page: 1,
    nextPageText: props.t("Suivant"),
    prePageText: props.t("Précedent"),
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: appOffre.length,
      },
    ],
  }
  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )
  const Columns = () => [
    {
      dataField: "name_appel_offre",
      text: props.t("Appel"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "date_parution",
      text: props.t("Date de parution"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "date_cloture",
      text: props.t("Date de cloture"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "secteur_activite",
      text: props.t("Secteur d'activité"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Cahier de charge",
      isDummyField: true,
      text: props.t("Cahier de charge"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            title="Telecharger"
            style={{
              color: "cadetblue",
              cursor: "pointer",
              paddingLeft: "5px",
              fontSize: "15px",
            }}
            className="fas fa-file-alt"
            onClick={() => downloadCahier(row)}
          ></i>
        </div>
      ),
    },
    {
      dataField: "Fichiers",
      isDummyField: true,
      text: "Fichiers",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.fichier.map((el, index) => (
          <span
            key={index}
            title={el.fiche}
            style={{ cursor: "pointer", paddingLeft: "10px" }}
            onClick={() => downloadFile(el)}
          >
            <i
              style={{
                paddingLeft: "5px",
                fontSize: "15px",
                color: "cornflowerblue",
              }}
              className="fas fa-file-download"
              title={el.fiche}
            ></i>
          </span>
        )),
    },
    {
      dataField: "Participation",
      isDummyField: true,
      text: props.t("Participation"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to={"/AppOffrePart?id=" + row.appel_offre_id}>
            <i
              style={{
                color: "cornflowerblue",
                cursor: "pointer",
                paddingLeft: "5px",
                fontSize: "15px",
              }}
              className="fas fa-folder-open"
            ></i>
          </Link>
        </div>
      ),
    },
  ]

  const { SearchBar } = Search
  useEffect(async () => {
    const res = await APIF.post("participant/list", {
      entreprise_id: entrepriseId,
    }).then(res => {
      setAppOffre(res.data.Participant)
    })
    setLoading(true)
  }, [])

  const downloadCahier = async row => {
    const res = await APIF.post(
      "appelle/offre/download_file",
      {
        annee: "2022",
        activite: row.secteur_activite,
        appel: row.name_appel_offre,
        cahier_charge: row.cahier_charge,
      },
      { responseType: "blob" }
    ).then(res => {
      FileDownload(res.data, `${row.cahier_charge}`)
    })
  }

  const downloadFile = async el => {
    const res = await APIF.post(
      "participant/download_file",
      {
        id: el.ligne_participant_id,
        annee: "2022",
      },
      { responseType: "blob" }
    ).then(res => {
      FileDownload(res.data, `${el.file}`)
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Appel d'offre")}</title>
        </MetaTags>
        {loading ? (
          <Container fluid>
            <Row>
              <Col xs="12">
                <ToolkitProvider
                  keyField="id"
                  data={appOffre}
                  columns={Columns()}
                  search
                  bootstrap4
                >
                  {toolkitProps => (
                    <div>
                      <Col sm="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        noDataIndication={() => <NoDataIndication />}
                        striped={false}
                        bordered={false}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"table-light"}
                        hover
                        pagination={paginationFactory(pageOptions)}
                        {...toolkitProps.baseProps}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("load_page")}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(DataTableAppOffre))
DataTableAppOffre.propTypes = {
  appOffre: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
