import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages

import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
// offre stage
import OffreStage from "pages/OffreDeStage/OffreStage"
import EditOffreStage from "pages/OffreDeStage/EditOffreStage"
// offre emploi
import OffreEmplois from "pages/OffreEmploi/OffreEmplois"
import EditOffreEmplois from "pages/OffreEmploi/EditOffreEmplois"
// stagiaires
import Stagiaires from "pages/Stagiaires/DataTableStagiaires"
// suivi stage
import SuiviStage from "pages/SuiviTache/DataTableSuiviTache"
import EditSuiviTache from "pages/SuiviTache/EditSuiviTache"
// evaluation
import EvaluationStage from "pages/EvaluationStage/DataTableEvaluationStage"
import EditEvaluerStage from "pages/EvaluationStage/EditEvaluerStage"
// candidature
import DataTableCandidature from "pages/Candidatures/DataTableCandidature"
import EditCandidature from "pages/Candidatures/EditCandidature"
//listDiplomer
import DataTabelListDiplomer from "../pages/ListDiplomer/DataTabelListDiplomer"
// soutenance
import Soutenance from "pages/Soutenance/Soutenance"
import DataTableSoutenance from "pages/Soutenance/DataTableSoutenance"
// compte
import Compte from "pages/Compte/Compte"
// appel d'offfre
import AppelOffre from "../pages/AppOffre/DataTableAppOffre"
import AppOffrePart from "../pages/AppOffre/AppOffrePart"
//
const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  // profile
  { path: "/profile", component: UserProfile },
  // offres stage
  { path: "/OffreStage", component: OffreStage },
  { path: "/EditOffreStage", component: EditOffreStage },
  // offres d'emploi
  { path: "/OffreEmplois", component: OffreEmplois },
  { path: "/EditOffreEmplois", component: EditOffreEmplois },
  // stagiaires
  { path: "/Stagiaires", component: Stagiaires },
  // Suivi stage
  { path: "/SuiviStage", component: SuiviStage },
  { path: "/EditSuiviTache", component: EditSuiviTache },
  // evaluation
  { path: "/EvaluationStage", component: EvaluationStage },
  { path: "/EditEvaluerStage", component: EditEvaluerStage },
  // { path: "/EditEvaluerStage", component: EditEvaluerStage },
  // candidature
  { path: "/Candidature", component: DataTableCandidature },
  { path: "/EditCandidature", component: EditCandidature },
  //listDiplomer
  { path: "/ListDiplome", component: DataTabelListDiplomer },
  // soutenance
  { path: "/Soutenance", component: Soutenance },
  { path: "/SoutenanceEtudiant", component: DataTableSoutenance },
  // compte
  { path: "/Compte", component: Compte },
  // appel d'offfre
  { path: "/AppelOffre", component: AppelOffre },
  { path: "/AppOffrePart", component: AppOffrePart },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
