import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import APIF from "../../apiF"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Container,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import "react-datepicker/dist/react-datepicker.css"
import { withTranslation } from "react-i18next"

const AppOffrePart = props => {
  var userAuth = JSON.parse(localStorage.getItem("userAuthEntreprise"))
  var entrepriseId = userAuth.user.id_entreprise
  var nomEntreprise = userAuth.user.name
  var entreprise = { value: entrepriseId, label: nomEntreprise }
  //
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [appelId, setAppelId] = useState("")
  const [indexLigne, setIndexLigne] = useState("")
  const [offreApp, setOffreApp] = useState("")
  const [arrayFile, setArrayFile] = useState([])
  const [array, setArray] = useState([])
  //
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setAppelId(id)
    //
    const res = await APIF.post("participant/get_by_id_entreprise", {
      appelle_offre_id: id,
      entreprise_id: entrepriseId,
    }).then(res => {
      setOffreApp(res.data.Participant.nom_offre)
      if (res.data.Participant.detail.length > 0) {
        setArrayFile(res.data.Participant.detail)
      } else {
        setArrayFile([{ fiche: "", fichier: "" }])
      }
      setLoading(true)
    })
  }, [])

  const addLigne = () => {
    let element = { fiche: "", fichier: "" }
    setArrayFile([...arrayFile, element])
  }

  const toggle = indexOfLigne => {
    setModal(!modal)
    setIndexLigne(indexOfLigne)
  }

  const toggleDelete = async () => {
    arrayFile.splice(indexLigne, 1)
    setModal(false)
  }

  const addFiche = (event, index) => {
    let fiche = event.target.value
    setArrayFile(
      arrayFile.map((el, id) =>
        id === index ? Object.assign(el, { fiche: fiche }) : el
      )
    )
  }

  const addFile = (event, index) => {
    let file = event.target.files[0]
    //
    let fichier = ""
    if (file != null) {
      fichier = file.name
    }
    //
    setArrayFile(
      arrayFile.map((el, id) =>
        id === index ? Object.assign(el, { fichier: fichier }) : el
      )
    )
    //
    let tabFichier = [...array]
    tabFichier.push(file)
    setArray(tabFichier)
  }

  const save = async () => {
    for (let index = 0; index < arrayFile.length; index++) {
      const file = array[index]
      let fiche = arrayFile[index].fiche
      let ficher = arrayFile[index].ficher
      if (fiche != "" && ficher != "") {
        const formData = new FormData()
        formData.append("entreprise", nomEntreprise)
        formData.append("appelle_offre_id", appelId)
        formData.append("document", file)
        formData.append("annee", "2022")
        //
        if (file != undefined) {
          const res = APIF.post("participant/upload_file", formData).then(
            resA => {
              if (arrayFile.length - 1 == index) {
                const res = APIF.post("participant/add", {
                  appelle_offre_id: appelId,
                  entreprise: entreprise,
                  array: arrayFile,
                })
                  .then(res => {
                    props.history.push("/AppelOffre")
                  })
                  .catch(() => {
                    toast.error("Problème lors de l'insertion !", {
                      containerId: "A",
                    })
                  })
              }
            }
          )
        } else {
          if (arrayFile.length - 1 == index) {
            const res = APIF.post("participant/add", {
              appelle_offre_id: appelId,
              entreprise: entreprise,
              array: arrayFile,
            })
              .then(res => {
                props.history.push("/AppelOffre")
              })
              .catch(() => {
                toast.error("Problème lors de l'insertion !", {
                  containerId: "A",
                })
              })
          }
        }
      } else {
        toast.error("Veuillez remplir les champs vides", {
          containerId: "A",
        })
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading ? (
          <Container fluid={true}>
            <Row>
              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <h5
                      className="font-size-16 me-2"
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        color: "rgb(85, 110, 230)",
                      }}
                    >
                      {"Appel d'offre"} :
                    </h5>
                    <h6
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "15px",
                      }}
                    >
                      {offreApp}
                    </h6>{" "}
                  </div>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th
                          style={{ textAlign: "center" }}
                          className="col-md-5"
                          scope="col"
                        >
                          {props.t("Fiche")}
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="col-md-5"
                          scope="col"
                        >
                          {props.t("Fichier")}
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="col-md-2"
                          scope="col"
                        >
                          <i
                            style={{
                              color: "#a0ceb8",
                              cursor: "pointer",
                            }}
                            className="fas fa-plus"
                            onClick={addLigne}
                          />
                        </th>
                      </tr>
                    </thead>
                    {arrayFile.map((el, index) => (
                      <tbody key={index}>
                        <tr>
                          <td className="col-md-5">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around",
                              }}
                            >
                              <Input
                                name="Nom"
                                placeholder="Entrer nom"
                                defaultValue={el.fiche}
                                type="text"
                                className="form-control"
                                onChange={e => addFiche(e, index)}
                              />
                            </div>
                          </td>
                          <td className="col-md-5">
                            <Input
                              onChange={e => addFile(e, index)}
                              className="form-control"
                              type="file"
                            />
                          </td>
                          <td
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                          >
                            <i
                              style={{ color: "#cc0000", cursor: "pointer" }}
                              className="mdi mdi-delete font-size-18"
                              id="deletetooltip"
                              onClick={() => toggle(index)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/AppelOffre")}
                        >
                          {props.t("Annuler")}
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={save}
                        >
                          {props.t("Confirmer")}
                        </button>
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Row>
            <ToastContainer
              transition={Slide}
              enableMultiContainer
              containerId={"A"}
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
            />
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("load_page")}
            </h4>
          </div>
        )}
      </div>
      {/* <> */}
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggle}
        >
          {props.t("Suppression ligne")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>
                {props.t("Êtes-Vous sûr de vouloir supprimer cette ligne ?")}
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleDelete}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation()(AppOffrePart)
AppOffrePart.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
