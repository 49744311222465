import axios from 'axios';
if(localStorage.getItem("userAuthStage")){
  var userAuthStage =JSON.parse(localStorage.getItem("userAuthStage"))
  var token = userAuthStage.user.api_token
}
export default axios.create({
  withCredentials:true,
  baseURL: `https://backstage.isggb.com/api/`,
  headers:{
  Authorization:`bearer ${token}` ,
  apipassword:"FAaaK32p0Xq04T0e",
  }

});
