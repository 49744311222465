import axios from "axios"
if (localStorage.getItem("userAuthEntreprise")) {
  var userAuthEntreprise = JSON.parse(localStorage.getItem("userAuthEntreprise"))
  var token = userAuthEntreprise.user.api_token
}
export default axios.create({
  withCredentials: true,
  baseURL: `https://backentreprise.isggb.com/api/`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
})
