import React, { useState, useEffect, useMemo } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import API from "../../api"
import APIS from "../../apiS"
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Card,
  CardBody,
  Container,
  CardTitle,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import validator from "validator"
import { set } from "lodash"
import { AvForm, AvField } from "availity-reactstrap-validation"
const UserProfile = props => {
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  const [entreprise, setEntreprise] = useState("")
  const [adresse, setAdresse] = useState("")
  const [gerant, setGerant] = useState("")
  const [telephone, setTelephone] = useState("")
  const [mobile, setMobile] = useState("")
  const [matricule, setMatricule] = useState("")
  const [email, setEmail] = useState("")
  //
  useEffect(async () => {
    var userAuthEntreprise = JSON.parse(
      localStorage.getItem("userAuthEntreprise")
    )
    var entreprise_id = userAuthEntreprise.user.id
    setId(entreprise_id)
    //
    const res = await API.post("entreprise/get_by_id", { id: 1 }).then(res => {
      setEntreprise(res.data.Entreprise.entreprise)
      setAdresse(res.data.Entreprise.adresse)
      setGerant(res.data.Entreprise.gerant)
      setTelephone(res.data.Entreprise.telephone)
      setMobile(res.data.Entreprise.mobile)
      setMatricule(res.data.Entreprise.matricule)
      setEmail(res.data.Entreprise.email)
      setLoading(true)
    })
  }, [])

  const save = async () => {
    if (entreprise == "" && gerant == "" && email == "" && matricule == "") {
      toast.error("Vérifier les champs vides!", {
        containerId: "A",
      })
    } else if (isNaN(telephone) == true) {
      toast.error("Numéro téléphone invalid!", {
        containerId: "A",
      })
    } else if (validator.isEmail(email) == false) {
      toast.error("Email invalid!", {
        containerId: "A",
      })
    } else {
      setLoading(false)
      const res = await API.post("entreprise/update", {
        id: 1,
        name: entreprise,
        matricule: matricule,
        email: email,
        adresse: adresse,
        telephone: telephone,
        mobile: mobile,
        gerant: gerant,
      })
        .then(res => {
          //setModal(false)
          //props.setSection(1)
        })
        .then(resS => {
          const resE = APIS.post("user/update_entreprise", {
            id: 1,
            name: entreprise,
            email: email,
          }).then(resE => {
            window.location.reload()
          })
        })
    }
  }

  return (
    <React.Fragment>
      {loading ? (
        <div className="page-content">
          <Container fluid>
            <Row>
              <Card>
                <CardBody>
                  <AvForm>
                    <CardTitle className="h4 mb-4" style={{ color: "#556ee6" }}>
                      {props.t("Information entreprise")}
                    </CardTitle>
                    <Form>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-lastname-input2">
                              {props.t("Entreprise")}
                            </Label>
                            <AvField
                              name="Entreprise"
                              placeholder="Entrer nom de l'entreprise"
                              type="text"
                              value={entreprise}
                              errorMessage="* Entreprise obligatoire"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              onChange={e => setEntreprise(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-lastname-input2">
                              {props.t("Adresse")}
                            </Label>
                            <Input
                              placeholder="Entrer l'adresse"
                              lg="3"
                              type="text"
                              className="form-control"
                              defaultValue={adresse}
                              onChange={e => setAdresse(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-lastname-input2">
                              {props.t("Gérant")}
                            </Label>
                            <AvField
                              name="Gérant"
                              placeholder="Entrer gérant"
                              type="text"
                              value={gerant}
                              errorMessage="* Gérant obligatoire"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              onChange={e => setGerant(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-lastname-input2">
                              {props.t("Téléphone")}
                            </Label>
                            <Input
                              placeholder="Entrer le n° de téléphone"
                              lg="3"
                              type="text"
                              className="form-control"
                              value={telephone}
                              onChange={e => setTelephone(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-lastname-input2">
                              {props.t("Téléphone-fixe")}
                            </Label>
                            <Input
                              placeholder="Entrer le n° de téléphone fixe"
                              lg="3"
                              type="text"
                              className="form-control"
                              value={mobile}
                              onChange={e => setMobile(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-lastname-input2">
                              {props.t("Matricule")}
                            </Label>
                            <AvField
                              name="Matricule"
                              placeholder="Entrer matricule"
                              type="text"
                              value={matricule}
                              errorMessage="* matricule obligatoire"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              onChange={e => setMatricule(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-lastname-input2">
                              {props.t("Email")}
                            </Label>
                            <Input
                              placeholder="Entrer l'email"
                              lg="3"
                              type="text"
                              className="form-control"
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {" "}
                      <Col lg="12">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                          className="text-center mt-4"
                        >
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={save}
                          >
                            {props.t("Modifier")}
                          </button>
                        </div>
                      </Col>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Row>
            <ToastContainer
              transition={Slide}
              enableMultiContainer
              containerId={"A"}
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
            />
          </Container>
        </div>
      ) : (
        <div style={{ marginTop: "8%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {" "}
            {props.t("load_page")}
          </h4>
        </div>
      )}
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(UserProfile))
UserProfile.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
