import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { useState } from "react"

const SidebarContent = props => {
  const ref = useRef()
  //
  var lng = localStorage.getItem("I18N_LANGUAGE")
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" className={lng == "ar" ? "sidebar-menu-ar" : ""}>
          <ul
            className={
              lng == "ar"
                ? "metismenu-ar list-unstyled"
                : "metismenu list-unstyled"
            }
            id="side-menu"
          >
            <li>
              <Link to="/dashboard" className="">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Actualités")}</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/Compte" className="">
                <i className="fas fa-user-circle"></i>
                <span>{props.t("Compte")}</span>
              </Link>
            </li> */}
            <li>
              <Link to="/OffreStage" className="">
                <i className="fas fa-clipboard"></i>
                <span>{props.t("Offre de stage")}</span>
              </Link>
            </li>
            <li>
              <a className="has-arrow ">
                <i className="fas fa-user-tie"></i>{" "}
                <span>{props.t("Emploi")}</span>
              </a>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/OffreEmplois">{props.t("Offre")}</Link>
                </li>
                <li>
                  <Link to="/Candidature">{props.t("Candidature")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <a className="has-arrow ">
                <i className="fas fa-users"></i>{" "}
                <span>{props.t("Stagiaire")}</span>
              </a>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/Stagiaires" className="">
                    <span>{props.t("List")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/SuiviStage" className="">
                    <span>{props.t("Suivi tâche")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/EvaluationStage" className="">
                    <span>{props.t("Evaluation stage")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/SoutenanceEtudiant" className="">
                    <span>{props.t("Soutenance")}</span>
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li>
              <Link to="/AppelOffre" className="">
                <i className="fas fa-file-import"></i>
                <span>{props.t("Appel d'offre")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/ListDiplome" className="">
                <i className="fas fa-user-graduate"></i>
                <span>{props.t("Diplomés")}</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
