import React, { useState, useEffect, useMemo } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import { Redirect, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import API from "../../api"
//i18n
import { withTranslation } from "react-i18next"
//Import Date Picker
import "react-datepicker/dist/react-datepicker.css"
import {
  Button,
  Card,
  CardBody,
  Container,
  Form,
  Row,
  Spinner,
} from "reactstrap"
//
const EvaluerStage = props => {
  // langue
  const lng = localStorage.getItem("I18N_LANGUAGE")
  // loading,id
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState("")
  // entete
  const [NomEtudt, setNomEtud] = useState("")
  const [Sujet, setSujet] = useState("")
  // data array
  const [arrayEvaluation, setArrayEvaluation] = useState([])
  //
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("evaluation/get_by_id", { id })
    if (res.data.status === 200) {
      setNomEtud(res.data.Affectation.etudiant)
      setSujet(res.data.Affectation.sujet)
      setArrayEvaluation(res.data.Affectation.arrayEvaluation)
      setId(id)
      setLoading(true)
    }
  }, [])

  const editEtat = (event, index, indice) => {
    let isChecked = event.target.checked
    if (isChecked == true) {
      var etatCheck = 1
    } else {
      var etatCheck = 0
    }
    let tabEvaluation = [...arrayEvaluation]
    let tabDetail = tabEvaluation[index].detail
    // tabDetail[indice].etat = etatCheck
    tabDetail: tabDetail.map((el, id) =>
      id === indice ? Object.assign(el, { etat: etatCheck }) : el
    )
    setArrayEvaluation(
      arrayEvaluation.map((el, id) =>
        id === index ? Object.assign(el, { detail: tabDetail }) : el
      )
    )
  }

  const editText = (event, index, indice) => {
    let text = event.target.value
    let tabEvaluation = [...arrayEvaluation]
    let tabDetail = tabEvaluation[index].detail
    tabDetail: tabDetail.map((el, id) =>
      id === indice ? Object.assign(el, { text: text }) : el
    )
    setArrayEvaluation(
      arrayEvaluation.map((el, id) =>
        id === index ? Object.assign(el, { detail: tabDetail }) : el
      )
    )
  }

  const save = async () => {
    const res = await API.post("evaluation/save", {
      affectationId: id,
      arrayEvaluation: arrayEvaluation,
    })
      .then(res => {
        props.history.push("/EvaluationStage")
      })
      .catch(() => {
        toast.error("Probléme lors de l'insertion", {
          containerId: "A",
        })
      })
  }

  return (
    <React.Fragment>
      {loading ? (
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Card>
                <CardBody>
                  <Form>
                    <div className="content-i">
                      <div className="content-box">
                        <div className="row pt-4">
                          <div className="col-sm-12">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="element-wrapper">
                                  <div
                                    className="element-box"
                                    data-bv-feedbackicons-valid="glyphicon glyphicon-ok"
                                    data-bv-feedbackicons-invalid="glyphicon glyphicon-remove"
                                    data-bv-feedbackicons-validating="glyphicon glyphicon-refresh"
                                  >
                                    <div className="title_stage">
                                      <h2 className="text-center">
                                        {props.t(
                                          "Fiche d'evaluation du stagiaire"
                                        )}
                                      </h2>
                                      <h5 className="text-center">
                                        {props.t("Etudiant")} : {NomEtudt}
                                      </h5>
                                      <h6 className="text-center">
                                        {props.t("Sujet")} : {Sujet}
                                      </h6>
                                    </div>
                                    <div
                                      style={{
                                        backgroundColor: "#dadbdd",
                                        padding: "5px",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <fieldset className="form-group">
                                        <div className="row pt-3">
                                          {arrayEvaluation.map((el, index) => (
                                            <div
                                              className="col-md-6 eval_signle"
                                              style={{ marginBottom: "15px" }}
                                              key={index}
                                            >
                                              <div className="ssTitle">
                                                <h6>{el.titre} </h6>
                                              </div>
                                              <div className="col-md-12">
                                                {/* <selon le type on va soit utiliser un bouton radio si ele.type == 0 OU on va utiliser un texteArea si ele.type == 1> */}
                                                {el.detail.map(
                                                  (ele, indice) => (
                                                    <div
                                                      className="form-group eval_holder"
                                                      key={indice}
                                                      style={{ padding: "3px" }}
                                                    >
                                                      {ele.type == 0 ? (
                                                        <input
                                                          type="radio"
                                                          data-error="Ce champ est obligatoire."
                                                          name={"name" + index}
                                                          checked={
                                                            ele.etat == 1
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={e =>
                                                            editEtat(
                                                              e,
                                                              index,
                                                              indice
                                                            )
                                                          }
                                                        />
                                                      ) : (
                                                        <textarea
                                                          data-error="Ce champ est obligatoire."
                                                          className="form-control"
                                                          defaultValue={
                                                            ele.text
                                                          }
                                                          onChange={e =>
                                                            editText(
                                                              e,
                                                              index,
                                                              indice
                                                            )
                                                          }
                                                        />
                                                      )}
                                                      &nbsp;
                                                      {ele.type == 0
                                                        ? ele.sousTitre
                                                        : null}
                                                      <div className="help-block with-errors"></div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </fieldset>
                                    </div>
                                  </div>
                                </div>
                                <div className="floated-customizer-panel">
                                  <div className="fcp-content">
                                    <div className="close-customizer-btn">
                                      <i className="os-icon os-icon-x" />
                                    </div>
                                    <div className="fcp-group">
                                      <div className="fcp-group-contents"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      direction: lng == "ar" ? "initial" : "initial",
                      marginTop: "15px",
                    }}
                  >
                    <div lg="4">
                      <Button
                        type="button"
                        color="warning"
                        className="btn btn-warning  mb-2 me-2"
                        onClick={() => props.history.push("/EvaluationStage")}
                      >
                        {props.t("Annuler")}
                      </Button>
                    </div>
                    <div lg="4">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={save}
                      >
                        {props.t("Confirmer")}
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>
      ) : (
        <div style={{ marginTop: "8%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {" "}
            {props.t("load_page")}
          </h4>
        </div>
      )}
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(EvaluerStage))
EvaluerStage.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
