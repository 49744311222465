import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Container,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { MetaTags } from "react-meta-tags"
import { withTranslation } from "react-i18next"

const DataTableStagiaires = props => {
  var lng = localStorage.getItem("I18N_LANGUAGE")
  var userAuth = JSON.parse(localStorage.getItem("userAuthEntreprise"))
  var entrepriseId = userAuth.user.id_entreprise
  //
  const [stagiaire, setStagiaire] = useState([])
  const [loading, setLoading] = useState(false)
  const [modalVal, setModalVal] = useState(false)
  const [id, setId] = useState("")
  const [stageId, setStageId] = useState("")
  //
  useEffect(async () => {
    const res = await API.post("stage/list_entreprise", {
      id_entreprise: entrepriseId,
    }).then(res => {
      setStagiaire(res.data.Sujet)
    })
    setLoading(true)
  }, [])
  //
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: stagiaire.length,
    page: 1,
    nextPageText: props.t("next"),
    prePageText: props.t("back"),
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: stagiaire.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )

  const Columns = () => [
    {
      dataField: "etudiant",
      text: props.t("Etudiant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "encadrant",
      text: props.t("Encadrant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "stage",
      text: props.t("Sujet"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    // {
    //   dataField: "Etat soutenance",
    //   isDummyField: true,
    //   text: props.t("Etat soutenance"),
    //   style: { textAlign: "center" },
    //   headerStyle: (colum, colIndex) => {
    //     return { textAlign: "center" }
    //   },
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, row) => (
    //     <div style={{ display: "flex", justifyContent: "center" }}>
    //       <i
    //         style={
    //           row.etat == 0
    //             ? { color: "red", display: "flex" }
    //             : { color: "green", display: "flex" }
    //         }
    //         className={row.etat == 0 ? "fas fa-times" : "fas fa-check"}
    //       >
    //         <p style={{ paddingLeft: "5px" }}>
    //           {row.etat == 0 ? "Non" : "Oui"}
    //         </p>
    //       </i>
    //     </div>
    //   ),
    // },
    {
      dataField: "Situation",
      isDummyField: true,
      text: props.t("Situation"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {row.etat_validation == 2 ? (
            <i
              className="fas fa-times"
              style={{ color: "red", display: "flex", cursor: "pointer" }}
              title="Stage invalide"
            ></i>
          ) : row.etat_validation == 0 ? (
            <i
              className="fas fa-hourglass-half"
              style={{ color: "orange", display: "flex", cursor: "pointer" }}
              title="Stage en cours"
            ></i>
          ) : (
            <i
              className="fas fa-check"
              style={{ color: "green", display: "flex", cursor: "pointer" }}
              title="Stage validé"
            ></i>
          )}
        </div>
      ),
    },
    {
      dataField: "Action",
      isDummyField: true,
      text: props.t("Action"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <i
            onClick={() => toggleVal(row)}
            style={{ cursor: "pointer" }}
            className="fas fa-cogs"
          ></i>
        </div>
      ),
    },
  ]

  const { SearchBar } = Search

  const toggleVal = row => {
    setId(row.id_etudiant)
    setStageId(row.id)
    setModalVal(!modalVal)
  }

  const closeModalVal = () => {
    setModalVal(!modalVal)
  }

  const edit = e => {
    const res = API.post("entreprise/validation_entreprise", {
      etudiant_id: id,
      stage_id: stageId,
      etat_entreprise_validation: e,
    }).then(res => {
      const resD = API.post("stage/list_entreprise", {
        id_entreprise: entrepriseId,
      }).then(res => {
        setStagiaire(res.data.Sujet)
      })
      setLoading(true)
      setModalVal(!modalVal)
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Liste stagiaires")}</title>
        </MetaTags>
        <Container fluid>
          <div>
            {loading ? (
              <Row>
                <Col xs="12">
                  <ToolkitProvider
                    keyField="id"
                    data={stagiaire}
                    columns={Columns()}
                    search
                    bootstrap4
                  >
                    {toolkitProps => (
                      <div>
                        <Col sm="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          noDataIndication={() => <NoDataIndication />}
                          striped={false}
                          bordered={false}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"table-light"}
                          hover
                          pagination={paginationFactory(pageOptions)}
                          {...toolkitProps.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </Col>
              </Row>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Spinner type="grow" className="ms-6" color="primary" />
                </div>
                <h4
                  style={{ textAlign: "center", marginTop: "2%" }}
                  className="ms-6"
                >
                  {" "}
                  {props.t("load_page")}
                </h4>
              </div>
            )}
          </div>
          <Modal isOpen={modalVal} toggle={closeModalVal} centered={true}>
            <div
              className="modal-header"
              style={{
                width: "100% !important",
                direction: lng == "ar" ? "rtl" : "initial",
                justifyContent: "center !important",
                margin: "0 auto",
                fontSize: "17px",
                fontWeight: "bold",
              }}
              toggle={closeModalVal}
            >
              {props.t("Mise à jour etat stage")}
            </div>
            <ModalBody>
              <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  FontSize: "14px",
                  FontWeight: "700",
                  LineHeight: "18.375px",
                }}
              >
                <div
                  className="hvr-push"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "15px",
                  }}
                >
                  <div>
                    <Button className="btn btn-success" onClick={() => edit(1)}>
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        className="fas fa-check-circle"
                      ></i>
                      {props.t("Valider")}
                    </Button>
                  </div>
                  <div>
                    <Button className="btn btn-danger" onClick={() => edit(2)}>
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        className="fas fa-check-circle"
                      ></i>
                      {props.t("Refuser")}
                    </Button>
                  </div>
                  <div>
                    <Button className="btn btn-warning" onClick={() => edit(0)}>
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        className="fas fa-check-circle"
                      ></i>
                      {props.t("En attente")}
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={closeModalVal}
                      className="btn btn-secondary"
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        className="fas fa-times-circle"
                      ></i>
                      {props.t("Annuler")}
                    </Button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableStagiaires))
DataTableStagiaires.propTypes = {
  Stagiaire: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
