import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import axios from "axios"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  Container,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { MetaTags } from "react-meta-tags"
import Select from "react-select"

const DataTabelListDiplomer = props => {
  const [loading, setLoading] = useState(true)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const [anneeScolaire, setAnneeScolaire] = useState([])
  const [selectAnneeScolaire, setSelectAnneeScolaire] = useState("")
  const [arrayAdmi, setArrayAdmi] = useState([
    {
      etudiant: "sima",
      email: "sima@gabdb",
      moyenne: "12",
      mention: "bien",
      sujet: "marketing",
      entreprise: "cresus",
      rang: "122",
    },
    {
      etudiant: "slim",
      email: "slim@gabdb",
      moyenne: "15",
      mention: "bien",
      sujet: "web",
      entreprise: "mtd",
      rang: "152",
    },
    {
      etudiant: "ahmed",
      email: "ahmed@gabdb",
      moyenne: "13",
      mention: "bien",
      sujet: "site",
      entreprise: "softodo",
      rang: "136",
    },
  ])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: arrayAdmi.length,
    page: 1,
    nextPageText: props.t("next"),
    prePageText: props.t("back"),
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: arrayAdmi.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )

  const { SearchBar } = Search

  const Columns = () => [
    {
      dataField: "etudiant",
      text: props.t("Etudiant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "email",
      text: props.t("Email"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "moyenne",
      text: props.t("Moyenne"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "mention",
      text: props.t("Mention"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "sujet",
      text: props.t("Sujet"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "entreprise",
      text: props.t("Entreprise"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "rang",
      text: props.t("Rang"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
  ]

  const changerAnnee = async e => {
    setSelectAnneeScolaire(e)
    var idAnnee = e.value
    var listAdmi = [
      {
        etudiant: "sadk",
        email: "sadak@gabdb",
        moyenne: "15",
        mention: "bien",
        sujet: "web",
        entreprise: "cresus",
        rang: "122",
      },
      {
        etudiant: "ali",
        email: "ali@gabdb",
        moyenne: "15",
        mention: "bien",
        sujet: "web",
        entreprise: "mtd",
        rang: "152",
      },
      {
        etudiant: "sou",
        email: "sou@gabdb",
        moyenne: "17",
        mention: "bien",
        sujet: "site",
        entreprise: "softodo",
        rang: "136",
      },
    ]
    setArrayAdmi(listAdmi)
  }

  //   const rowEvent = {
  //     onClick: async (e, row) => {
  //       props.history.push("/EditOffreStage?id=" + row.id)
  //     },
  //   }

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <div className="page-content">
            <MetaTags>
              <title>{props.t("Diplomés")}</title>
            </MetaTags>
            <Container fluid>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Col xs="12">
                  <ToolkitProvider
                    keyField="id"
                    data={arrayAdmi}
                    columns={Columns()}
                    search
                    bootstrap4
                  >
                    {toolkitProps => (
                      <div>
                        <Col sm="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                            marginBottom: "10px",
                          }}
                        >
                          <button
                            type="button"
                            className="font-16 btn-block btn btn-primary btn btn-primary"
                          >
                            {props.t("Télécharger la liste des diplômés")}
                            <i
                              style={{ marginLeft: "5px" }}
                              className="mdi mdi-file-document"
                            ></i>
                          </button>
                        </div>
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          noDataIndication={() => <NoDataIndication />}
                          striped={false}
                          bordered={false}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"table-light"}
                          hover
                          pagination={paginationFactory(pageOptions)}
                          {...toolkitProps.baseProps}
                        />
                        <div
                          className="col-md-4"
                          style={{ marginBottom: "10px" }}
                        ></div>
                      </div>
                    )}
                  </ToolkitProvider>
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {props.t("load_page")}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTabelListDiplomer))
DataTabelListDiplomer.propTypes = {
  arrayAdmi: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
