import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

const DataTableOffreStage = props => {
  var lng = localStorage.getItem("I18N_LANGUAGE")
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  // Data Table
  const [offreStage, setOffreStage] = useState([])
  useEffect(async () => {
    var lang = localStorage.getItem("I18N_LANGUAGE")
    const res = await API.get("stage/list", {
      lang: lang,
    }).then(res => {
      setOffreStage(res.data.Stage)
    })
    setLoading(true)
  }, [])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: offreStage.length,
    page: 1,
    nextPageText: props.t("next"),
    prePageText: props.t("back"),
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: offreStage.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )

  const { SearchBar } = Search

  const Columns = () => [
    {
      dataField: "sujet",
      text: props.t("Sujet"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "periode_stage",
      text: props.t("Période de stage"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "stage_type",
      text: props.t("Type de stage"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "stage_specialite",
      text: props.t("Spécialité"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "niveau",
      text: props.t("Niveau"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "etat",
      isDummyField: true,
      text: props.t("Etat"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={
              row.etat == 0
                ? { color: "orange", display: "flex" }
                : row.etat == 2
                ? { color: "red", display: "flex" }
                : { color: "green", display: "flex" }
            }
            className={
              row.etat == 0
                ? "fas fa-times"
                : row.etat == 2
                ? "fas fa-ban"
                : "fas fa-check"
            }
          >
            <p style={{ paddingLeft: "5px" }}>
              {row.etat == 0
                ? lng == "ar"
                  ? "غير مفعل"
                  : lng == "en"
                  ? "Inactive"
                  : "Inactif"
                : row.etat == 2
                ? lng == "ar"
                  ? "رفض"
                  : lng == "en"
                  ? "Refuse"
                  : "Refuser"
                : lng == "ar"
                ? "نشيط"
                : lng == "en"
                ? "Active"
                : "Actif"}
            </p>
          </i>
        </div>
      ),
    },
  ]

  const rowEvent = {
    onClick: async (e, row) => {
      props.history.push("/EditOffreStage?id=" + row.id)
    },
  }

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={offreStage}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      rowEvents={rowEvent}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {props.t("load_page")}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableOffreStage))
DataTableOffreStage.propTypes = {
  offreStage: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
