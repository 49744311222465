import React, { useState, useEffect, useMemo } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import { Redirect, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import API from "../../api"
//Import Date Picker
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import APID from "../../apiD"

const EditOffreStage = props => {
  const lng = localStorage.getItem("I18N_LANGUAGE")
  const [loading, setLoading] = useState(false)
  const [periode, setPeriode] = useState("")
  const [description, setDescription] = useState("")
  const [sujet, setSujet] = useState("")
  const [arrayType, setArrayType] = useState([])
  const [type, setType] = useState([])
  const [arraySpec, setArraySpec] = useState([])
  const [specialite, setSpecialite] = useState([])
  const [arrayNiveau, setArrayNiveau] = useState([])
  const [niveau, setNiveau] = useState([])
  const [id, setId] = useState("")
  const [modal, setModal] = useState(false)

  useEffect(async () => {
    // type
    const resM = await API.get("type_stage/select", {
      lang: lng,
    }).then(resM => {
      setArrayType(resM.data.Type_stage)
    })
    // specialite
    const resL = await APID.get("specialite/select").then(resL => {
      setArraySpec(resL.data.Specialites)
    })
    //
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("stage/getById", { id })
    if (res.data.status === 200) {
      setLoading(true)
      setId(id)
      setDescription(res.data.Stage.description)
      setPeriode(res.data.Stage.periode_stage)
      setSujet(res.data.Stage.sujet)
      setType(res.data.Stage.type_stage)
      setSpecialite(res.data.Stage.specialite)
      setNiveau(res.data.Stage.niveau)
    }
  }, [])

  const changeSpec = e => {
    setSpecialite(e)
    // niveau
    if (e.length > 0) {
      const res = APID.post("niveau/get_by_specialites", {
        specialites: e,
      }).then(res => {
        setArrayNiveau(res.data.Niveaux_By_Specialite)
      })
    } else {
      setArrayNiveau([])
    }
  }

  const toggleDelete = async () => {
    const res = await API.post("stage/delete", { id }).then(res => {
      setModal(!modal)
      props.history.push("/OffreStage")
    })
  }
  const toggle = () => {
    setModal(!modal)
  }

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    //
    const res = await API.post("stage/edit", {
      id: id,
      lang: lng,
      periode_stage: periode,
      description: description,
      sujet: sujet,
      type_stage: type,
      specialite: specialite,
      niveau: niveau,
    })
      .then(res => {
        props.history.push("/OffreStage")
      })
      .catch(() => {
        toast.error(":sens_interdit: Veuillez vérifier tous les champs !", {
          containerId: "A",
        })
      })
  }

  return (
    <React.Fragment>
      {loading ? (
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4" style={{ color: "#556ee6" }}>
                    {props.t("Modification offre de stage")}
                  </CardTitle>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Sujet")}
                          </Label>
                          <Input
                          name="Sujet"
                            lg="3"
                            className="form-control"
                            type="text"
                            value={sujet}
                            onChange={e => setSujet(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Période de stage")}
                          </Label>
                          <Input
                          name="Période de stage"
                            lg="3"
                            className="form-control"
                            type="text"
                            value={periode}
                            onChange={e => setPeriode(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Type")}
                          </Label>
                          <Select
                          name="Type"
                            isMulti={true}
                            options={arrayType}
                            isSearchable={true}
                            onChange={setType}
                            value={type}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Spécialité")}
                          </Label>
                          <Select
                          name="Spécialité"
                            isMulti={true}
                            options={arraySpec}
                            isSearchable={true}
                            onChange={e => changeSpec(e)}
                            value={specialite}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Niveau")}
                          </Label>
                          <Select
                          name="Niveau"
                            isMulti={true}
                            options={arrayNiveau}
                            isSearchable={true}
                            onChange={setNiveau}
                            value={niveau}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Description")}
                          </Label>
                          <Input
                          name="Description"
                            lg="3"
                            className="form-control"
                            placeholder="Entre  Description"
                            type="textarea"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      direction: lng == "ar" ? "initial" : "initial",
                    }}
                  >
                    {" "}
                    <div lg="4">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                        name="Suppression"
                          type="button"
                          color="danger"
                          className="btn btn-primary  mb-2 me-2"
                          onClick={toggle}
                        >
                          {props.t("Suppression")}
                        </Button>
                      </div>
                    </div>
                    <di lg="4">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                        name="annuler"
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/OffreStage")}
                        >
                          {props.t("annuler")}
                        </Button>
                      </div>
                    </di>
                    <div lg="4">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                        name="Confirmer"
                          type="button"
                          className="btn btn-primary "
                          onClick={edit}
                        >
                          {props.t("Confirmer")}
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
          <Modal isOpen={modal} toggle={toggle} centered={true}>
            <div
              className="modal-header"
              style={{
                width: "100% !important",
                justifyContent: "center !important",
                margin: "0 auto",
                fontSize: "17px",
              }}
              toggle={toggle}
            >
              {props.t("Suppression offre de stage")}
            </div>
            <ModalBody>
              <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  FontSize: "14px",
                  FontWeight: "700",
                  LineHeight: "18.375px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    direction: lng == "ar" ? "initial" : "initial",
                  }}
                >
                  <p>
                    {props.t(
                      "Êtes-Vous sûr de vouloir supprimer cette offre ?"
                    )}
                  </p>
                </div>
                <div
                  className="hvr-push"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "15px",
                  }}
                >
                  <div>
                    <Button
                    name="oui"
                      color="info"
                      className="btn-rounded "
                      onClick={toggleDelete}
                    >
                      <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-up"
                      ></i>
                      {props.t("oui")}
                    </Button>
                  </div>
                  <div>
                    <Button
                    name="non"
                      onClick={toggle}
                      color="danger"
                      className="btn-rounded "
                    >
                      <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-down"
                      ></i>
                      {props.t("non")}
                    </Button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </div>
      ) : (
        <div style={{ marginTop: "8%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {" "}
            {props.t("load_page")}
          </h4>
        </div>
      )}
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(EditOffreStage))
EditOffreStage.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
