import React, { useState, useEffect, useMemo } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import { Redirect, withRouter } from "react-router-dom"
import PropTypes, { array, element } from "prop-types"
import API from "../../api"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
//
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import APIS from "../../apiS"

const EditSuiviTache = props => {
  var lng = localStorage.getItem("I18N_LANGUAGE")
  //
  const [loading, setLoading] = useState(false)
  const [idEtud, setIdEtud] = useState("")
  const [idEncad, setIdEncad] = useState("")
  const [titreTache, setTitreTache] = useState([])
  const [arrayPriorite, setArrayPriorite] = useState([])
  const [priorite, setPriorite] = useState("")

  const [modal, setModal] = useState("")
  const [modalDelLigne, setModalDelLigne] = useState(false)
  const [indexLigne, setIndexLigne] = useState(false)
  const [indiceLigne, setIndiceLigne] = useState(false)
  // liste
  const [arrayTache, setArrayTache] = useState([])
  const [arrayTacheProch, setArrayTacheProch] = useState([])
  const [arrayTacheRet, setArrayTacheRet] = useState([])
  const [arrayTacheValid, setArrayTacheValid] = useState([])
  //
  const [arrayDetTache, setArrayDetTache] = useState([
    {
      tache: "",
      date: new Date(),
      priorite: "",
      etatValid: false,
    },
  ])
  const [modalLigneDel, setModalLigneDel] = useState(false)
  const [indLign, setIndLign] = useState("")
  const [modalSuppTache, setModalSuppTache] = useState(false)
  const [idSuppTache, setIdSuppTache] = useState("")
  //
  const [nomStage, setNomStage] = useState("")
  const [nomEtud, setNomEtud] = useState("")
  //
  useEffect(async () => {
    // get by id
    var url = window.location.href
    var array = url.split("=")
    var tab = array[1]
    var tabId = tab.split("/")
    var id_etudiant = tabId[0]
    var id_encadrant = tabId[1]
    //
    setIdEtud(id_etudiant)
    setIdEncad(id_encadrant)
    const resD = await APIS.post("tache/list", {
      affectation_etudiant_id: id_etudiant,
    }).then(resD => {
      // Mise en forme stage
      let tabTache = []
      if (resD.data.Tache.array.list != undefined) {
        tabTache = resD.data.Tache.array.list
        for (let index = 0; index < tabTache.length; index++) {
          let tabSousTache = tabTache[index].detTache
          for (let indice = 0; indice < tabSousTache.length; indice++) {
            // convert date seance
            let date = new Date(tabSousTache[indice].date)
            tabTache[index].detTache[indice].date = date
          }
        }
        setArrayTache(tabTache)
        setArrayTacheProch(resD.data.Tache.array.prochainement)
        setArrayTacheRet(resD.data.Tache.array.retard)
        setArrayTacheValid(resD.data.Tache.array.valider)
        setNomStage(resD.data.Tache.etd.stage)
        setNomEtud(resD.data.Tache.etd.nameEdt)
        setLoading(true)
      } else if (resD.data.Tache.array.length == 0) {
        setNomStage(resD.data.Tache.etd.stage)
        setNomEtud(resD.data.Tache.etd.nameEdt)
        setLoading(true)
      }
    })
    //
    const res = await APIS.post("priorite/select", {
      lang: lng,
    }).then(res => {
      setArrayPriorite(res.data.Priorité)
    })
  }, [])

  const addLigneNewDet = () => {
    let element = {
      tache: "",
      date: new Date(),
      priorite: "",
      etatValid: false,
    }
    setArrayDetTache([...arrayDetTache, element])
  }

  const toggleDelLig = index => {
    setModalLigneDel(!modalLigneDel)
    setIndLign(index)
  }

  const delNewLigneDet = () => {
    let tabDetTache = [...arrayDetTache]
    tabDetTache.splice(indLign, 1)
    setArrayDetTache(tabDetTache)
    setModalLigneDel(!modalLigneDel)
  }

  const toggle = id => {
    let tabDetTache = [
      {
        tache: "",
        date: new Date(),
        priorite: "",
        etatValid: false,
      },
    ]
    setArrayDetTache(tabDetTache)
    setModal(!modal)
  }

  const addTache = (event, index) => {
    let tache = event.target.value
    setArrayDetTache(
      arrayDetTache.map((el, id) =>
        id === index ? Object.assign(el, { tache: tache }) : el
      )
    )
  }

  const addDate = (event, index) => {
    let date = event
    setArrayDetTache(
      arrayDetTache.map((el, id) =>
        id === index ? Object.assign(el, { date: date }) : el
      )
    )
  }

  const addPriorite = (event, index) => {
    let priorite = event
    setArrayDetTache(
      arrayDetTache.map((el, id) =>
        id === index ? Object.assign(el, { priorite: priorite }) : el
      )
    )
  }

  const add = async () => {
    let titTache = titreTache
    let tabDetTache = [...arrayDetTache]
    let newObj = {
      titreTache: titTache,
      detTache: tabDetTache,
    }
    let tabTache = [...arrayTache]
    tabTache.push(newObj)
    setArrayTache(tabTache)
    setModal(!modal)
  }

  const addLigneAncDet = index => {
    let element = {
      tache: "",
      date: new Date(),
      priorite: "",
      etatValid: false,
    }
    let tabTache = [...arrayTache]
    tabTache[index].detTache.push(element)
    setArrayTache(tabTache)
  }

  const addAncTache = (event, index, indice) => {
    let tache = event.target.value
    let tabTache = [...arrayTache]
    let tabDetTache = tabTache[index].detTache
    tabDetTache: tabDetTache.map((el, id) =>
      id === indice ? Object.assign(el, { tache: tache }) : el
    )
    setArrayTache(
      arrayTache.map((el, id) =>
        id === index ? Object.assign(el, { detTache: tabDetTache }) : el
      )
    )
  }

  const addAncDate = (event, index, indice) => {
    let date = event
    let tabTache = [...arrayTache]
    let tabDetTache = tabTache[index].detTache
    tabDetTache: tabDetTache.map((el, id) =>
      id === indice ? Object.assign(el, { date: date }) : el
    )
    setArrayTache(
      arrayTache.map((el, id) =>
        id === index ? Object.assign(el, { detTache: tabDetTache }) : el
      )
    )
  }

  const addAncPriorite = (event, index, indice) => {
    let priorite = event
    let tabTache = [...arrayTache]
    let tabDetTache = tabTache[index].detTache
    tabDetTache: tabDetTache.map((el, id) =>
      id === indice ? Object.assign(el, { priorite: priorite }) : el
    )
    setArrayTache(
      arrayTache.map((el, id) =>
        id === index ? Object.assign(el, { detTache: tabDetTache }) : el
      )
    )
  }

  const addEtatValid = (event, index, indice) => {
    let etat = event
    let tabTache = [...arrayTache]
    let tabDetTache = tabTache[index].detTache
    tabDetTache: tabDetTache.map((el, id) =>
      id === indice ? Object.assign(el, { etatValid: etat }) : el
    )
    setArrayTache(
      arrayTache.map((el, id) =>
        id === index ? Object.assign(el, { detTache: tabDetTache }) : el
      )
    )
  }

  const toggleDelAncLigne = (index, indice) => {
    setIndexLigne(index)
    setIndiceLigne(indice)
    setModalDelLigne(!modalDelLigne)
  }

  const toggleDelAncLigneClose = () => {
    setModalDelLigne(!modalDelLigne)
  }

  const delAncLigneDet = () => {
    let tabTache = [...arrayTache]
    tabTache[indexLigne].detTache.splice(indiceLigne, 1)
    setArrayDetTache(tabTache)
    setModalDelLigne(!modalDelLigne)
  }

  const toggleSuppTache = index => {
    setModalSuppTache(!modalSuppTache)
    setIdSuppTache(index)
  }

  const SuppTache = () => {
    let tabTache = [...arrayTache]
    tabTache.splice(idSuppTache, 1)
    setArrayTache(tabTache)
    setModalSuppTache(!modalSuppTache)
  }

  const closeToggle = () => {
    setModalSuppTache(!modalSuppTache)
  }

  const save = async () => {
    // Mise a jour format date
    let tabTache = [...arrayTache]
    for (let index = 0; index < tabTache.length; index++) {
      let tabSousTache = tabTache[index].detTache
      for (let indice = 0; indice < tabSousTache.length; indice++) {
        // convert date seance
        let date = tabSousTache[indice].date
        let month = "" + (date.getMonth() + 1)
        let day = "" + date.getDate()
        let year = date.getFullYear()
        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day
        let convertDate = [year, month, day].join("-")
        tabTache[index].detTache[indice].date = convertDate
      }
    }
    // Add
    const res = await APIS.post("tache/add", {
      affectation_etudiant_id: idEtud,
      affectation_encadrent_id: idEncad,
      arrayTache: tabTache,
    })
      .then(res => {
        props.history.push("/SuiviStage")
      })
      .catch(() => {
        toast.error("Probléme lors de l'insertion", {
          containerId: "A",
        })
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {loading ? (
            <Row>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4" style={{ color: "#556ee6" }}>
                    {props.t("État d'avancement des tâches")}
                  </CardTitle>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "27%" }}>
                      {/* <> */}
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <h5 style={{ color: "green" }}>
                          {props.t("Validé")}{" "}
                          <i
                            style={{
                              fontSize: "20px",
                              paddingRight: "5px",
                              color: "seagreen",
                            }}
                            className="far fa-check-circle"
                          ></i>
                        </h5>
                      </span>
                      {arrayTacheValid.map((el, index) => (
                        <div className="card" key={index}>
                          <div className="card-title">{el.titreTache}</div>
                          {el.detTache.map((ele, indice) => (
                            <ul
                              className="verti-timeline list-unstyled"
                              key={indice}
                            >
                              <li className="event-list">
                                <div className="event-timeline-dot">
                                  <i className="bx bx-right-arrow-circle font-size-18" />
                                </div>
                                <div className="d-flex">
                                  <div className="flex-shrink-0 me-3">
                                    <h5 className="font-size-14">
                                      {ele.date}
                                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                    </h5>
                                  </div>
                                  <div className="flex-grow-1">
                                    <div>
                                      {ele.tache} - {ele.priorite.label}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          ))}
                        </div>
                      ))}
                      {/* <> */}
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <h5 style={{ color: "coral" }}>
                          {props.t("Prochainement")}
                          <i
                            style={{
                              fontSize: "20px",
                              paddingLeft: "5px",
                              color: "coral",
                            }}
                            className="fas fa-hourglass-half"
                          ></i>
                        </h5>
                      </span>
                      {arrayTacheProch.map((el, index) => (
                        <div className="card" key={index}>
                          <div className="card-title">{el.titreTache}</div>
                          {el.detTache.map((ele, indice) => (
                            <ul
                              className="verti-timeline list-unstyled"
                              key={indice}
                            >
                              <li className="event-list">
                                <div className="event-timeline-dot">
                                  <i className="bx bx-right-arrow-circle font-size-18" />
                                </div>
                                <div className="d-flex">
                                  <div className="flex-shrink-0 me-3">
                                    <h5 className="font-size-14">
                                      {ele.date}
                                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                    </h5>
                                  </div>
                                  <div className="flex-grow-1">
                                    <div>
                                      {ele.tache} - {ele.priorite.label}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          ))}
                        </div>
                      ))}
                      {/* <> */}
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <h5 style={{ color: "brown" }}>
                          {props.t("En retard")}
                          <i
                            style={{
                              fontSize: "20px",
                              paddingLeft: "5px",
                              color: "brown",
                            }}
                            className="fas fa-hourglass-end"
                          ></i>
                        </h5>
                      </span>
                      {arrayTacheRet.map((el, index) => (
                        <div className="card" key={index}>
                          <div className="card-title">{el.titreTache}</div>
                          {el.detTache.map((ele, indice) => (
                            <ul
                              className="verti-timeline list-unstyled"
                              key={indice}
                            >
                              <li className="event-list">
                                <div className="event-timeline-dot">
                                  <i className="bx bx-right-arrow-circle font-size-18" />
                                </div>
                                <div className="d-flex">
                                  <div className="flex-shrink-0 me-3">
                                    <h5 className="font-size-14">
                                      {ele.date}
                                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                    </h5>
                                  </div>
                                  <div className="flex-grow-1">
                                    <div>
                                      {ele.tache} - {ele.priorite.label}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          ))}
                        </div>
                      ))}
                      {/* <> */}
                    </div>
                    <div
                      style={{
                        width: "73%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ display: "flex" }}>
                          <h5>{props.t("Etudiant")}:</h5>
                          <h7 style={{ paddingLeft: "7px" }}>{nomEtud}</h7>
                        </span>
                        <span style={{ display: "flex" }}>
                          <h5>{props.t("Sujet")}:</h5>
                          <h7 style={{ paddingLeft: "7px" }}>{nomStage}</h7>
                        </span>
                        <Button
                          type="button"
                          color="primary"
                          className="btn btn-info  mb-1 me-2"
                          onClick={toggle}
                        >
                          <i
                            style={{ paddingRight: "5px", color: "white" }}
                            className="fas fa-plus-circle"
                          ></i>
                          {props.t("Ajouter une tâche")}
                        </Button>
                      </div>
                      <div>
                        {arrayTache.map((el, index) => (
                          <div className="col" key={index}>
                            <div
                              data-rbd-draggable-context-id={1}
                              data-rbd-draggable-id="column-draggable-1"
                              className="react-kanban-column"
                              style={{
                                height: "100%",
                                minHeight: "28px",
                                display: "inline-block",
                                verticalAlign: "top",
                                width: "100%",
                                borderColor: "blue",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "stretch",
                                  borderColor: "blue",
                                }}
                                tabIndex={0}
                                aria-describedby="rbd-hidden-text-1-hidden-text-16"
                                data-rbd-drag-handle-draggable-id="column-draggable-1"
                                data-rbd-drag-handle-context-id={1}
                                draggable="false"
                              >
                                <div
                                  className="mb-4 h4 card-title"
                                  style={{ borderColor: "blue" }}
                                >
                                  {props.t("Date de publication")} :{" "}
                                  {el.date_pub}
                                </div>
                                <div
                                  className="mb-4 h4 card-title"
                                  style={{ borderColor: "blue" }}
                                >
                                  {props.t("Titre")} : {el.titreTache}
                                </div>
                                <div>
                                  <i
                                    style={{
                                      color: "#cc0000",
                                      cursor: "pointer",
                                    }}
                                    className="mdi mdi-delete font-size-18"
                                    id="deletetooltip"
                                    onClick={() => toggleSuppTache(index)}
                                  />
                                </div>
                              </div>
                              <div
                                data-rbd-droppable-id={1}
                                data-rbd-droppable-context-id={1}
                                style={{
                                  minHeight: "inherit",
                                  height: "inherit",
                                }}
                              >
                                <div
                                  data-rbd-draggable-context-id={1}
                                  data-rbd-draggable-id={11}
                                  tabIndex={0}
                                  aria-describedby="rbd-hidden-text-1-hidden-text-16"
                                  data-rbd-drag-handle-draggable-id={11}
                                  data-rbd-drag-handle-context-id={1}
                                  draggable="false"
                                >
                                  <div
                                    style={{
                                      display: "inline-block",
                                      whiteSpace: "normal",
                                      width: "100%",
                                    }}
                                  >
                                    <div key={index} className="task-box card">
                                      <div className="borad-width card-body">
                                        <table className="table table-bordered mt-4">
                                          <thead>
                                            <tr>
                                              <th
                                                style={{
                                                  textAlign: "center",
                                                }}
                                                className="col-md-2"
                                                scope="col"
                                              >
                                                {props.t("Tâche")}
                                              </th>
                                              <th
                                                style={{
                                                  textAlign: "center",
                                                }}
                                                className="col-md-2"
                                                scope="col"
                                              >
                                                {props.t("Date limite")}
                                              </th>
                                              <th
                                                style={{
                                                  textAlign: "center",
                                                }}
                                                className="col-md-2"
                                                scope="col"
                                              >
                                                {props.t("Priorité")}
                                              </th>
                                              <th
                                                style={{
                                                  textAlign: "center",
                                                }}
                                                className="col-md-2"
                                                scope="col"
                                              >
                                                {props.t("Etat")}
                                              </th>
                                              <th
                                                style={{
                                                  textAlign: "center",
                                                }}
                                                className="col-md-2"
                                                scope="col"
                                              >
                                                <i
                                                  style={{
                                                    color: "#a0ceb8",
                                                    cursor: "pointer",
                                                  }}
                                                  className="fas fa-plus"
                                                  onClick={() =>
                                                    addLigneAncDet(index)
                                                  }
                                                />
                                              </th>
                                            </tr>
                                          </thead>
                                          {el.detTache.map((ele, indice) => (
                                            <tbody key={indice}>
                                              <tr>
                                                <td className="col-md-3">
                                                  <Input
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                    type="text"
                                                    className="form-control"
                                                    onChange={e =>
                                                      addAncTache(
                                                        e,
                                                        index,
                                                        indice
                                                      )
                                                    }
                                                    value={ele.tache}
                                                  />
                                                </td>
                                                <td className="col-md-3">
                                                  <DatePicker
                                                    selected={ele.date}
                                                    className="form-control ddate"
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={e =>
                                                      addAncDate(
                                                        e,
                                                        index,
                                                        indice
                                                      )
                                                    }
                                                  />
                                                </td>
                                                <td className="col-md-3">
                                                  <Select
                                                    options={arrayPriorite}
                                                    isSearchable={true}
                                                    onChange={e =>
                                                      addAncPriorite(
                                                        e,
                                                        index,
                                                        indice
                                                      )
                                                    }
                                                    value={ele.priorite}
                                                  />
                                                </td>
                                                <td className="col-md-1">
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <Switch
                                                      onChange={e =>
                                                        addEtatValid(
                                                          e,
                                                          index,
                                                          indice
                                                        )
                                                      }
                                                      checked={ele.etatValid}
                                                      offColor={"#FF0000"}
                                                      boxShadow={"no"}
                                                      activeBoxShadow={"yes"}
                                                      width={50}
                                                      height={15}
                                                    />
                                                  </div>
                                                </td>
                                                <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <i
                                                    style={{
                                                      color: "#cc0000",
                                                      cursor: "pointer",
                                                    }}
                                                    className="mdi mdi-delete font-size-18"
                                                    id="deletetooltip"
                                                    onClick={() =>
                                                      toggleDelAncLigne(
                                                        index,
                                                        indice
                                                      )
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            </tbody>
                                          ))}
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                <Spinner type="grow" className="ms-6" color="primary" />
              </div>
              <h4
                style={{ textAlign: "center", marginTop: "2%" }}
                className="ms-6"
              >
                {" "}
                {props.t("load_page")}
              </h4>
            </div>
          )}
          {arrayTache.length > 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                direction: lng == "ar" ? "initial" : "initial",
              }}
            >
              {" "}
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
                >
                  <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={() => props.history.push("/SuiviStage")}
                  >
                    {props.t("Annuler")}
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={save}
                  >
                    {props.t("Confirmer")}
                  </button>
                </div>
              </Col>
            </div>
          ) : null}
        </Container>
        <ToastContainer
          transition={Slide}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
        />
      </div>
      {/* Modal nouvelle tache */}
      <Modal
        isOpen={modal}
        toggle={toggle}
        style={{
          maxWidth: "700px",
          width: "100%",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        fade={false}
      >
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
            display: "flex",
            alignItems: "baseline",
          }}
          toggle={toggle}
        >
          {props.t("Ajouter une nouvelle tâche")} :
        </div>
        <ModalBody style={{ direction: lng == "ar" ? "rtl" : "initial" }}>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col lg="6">
              <div className="mb-3" style={{ textAlign: "center" }}>
                <Label for="basicpill-firstname-input1">
                  {props.t("Nom de la tâche")}
                </Label>
                <Input
                  lg="3"
                  className="form-control"
                  placeholder={props.t("Nom de la tâche")}
                  type="text"
                  onChange={e => setTitreTache(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <table className="table table-bordered mt-4">
              <thead>
                <tr>
                  <th
                    style={{ textAlign: "center" }}
                    className="col-md-3"
                    scope="col"
                  >
                    {props.t("Tâche")}
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="col-md-2"
                    scope="col"
                  >
                    {props.t("Date limite")}
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="col-md-3"
                    scope="col"
                  >
                    {props.t("Priorité")}
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="col-md-2"
                    scope="col"
                  >
                    <i
                      style={{
                        color: "#a0ceb8",
                        cursor: "pointer",
                      }}
                      className="fas fa-plus"
                      onClick={addLigneNewDet}
                    />
                  </th>
                </tr>
              </thead>
              {arrayDetTache.map((el, index) => (
                <tbody key={index}>
                  <tr>
                    <td className="col-md-2">
                      <Input
                        style={{ textAlign: "center" }}
                        type="text"
                        className="form-control"
                        placeholder={props.t("Tâche")}
                        onChange={e => addTache(e, index)}
                      />
                    </td>
                    <td className="col-md-3">
                      <DatePicker
                        selected={el.date}
                        className="form-control ddate"
                        dateFormat="dd/MM/yyyy"
                        onChange={e => addDate(e, index)}
                      />
                    </td>
                    <td className="col-md-4">
                      <Select
                        options={arrayPriorite}
                        isSearchable={true}
                        value={el.priorite}
                        onChange={e => addPriorite(e, index)}
                        placeholder={props.t("Select")}
                      />
                    </td>
                    <td className="col-md-3" style={{ textAlign: "center" }}>
                      <i
                        style={{ color: "#cc0000" }}
                        className="mdi mdi-delete font-size-18"
                        id="deletetooltip"
                        onClick={() => toggleDelLig(index)}
                      />
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </Row>
        </ModalBody>
        <CardFooter>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <div lg="6">
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={toggle}
              >
                {props.t("Annuler")}
              </Button>
            </div>
            <div lg="6">
              <button type="button" className="btn btn-primary " onClick={add}>
                {props.t("Confirmer")}
              </button>
            </div>
          </div>
        </CardFooter>
      </Modal>
      {/* supprimer nouvelle tache */}
      <Modal isOpen={modalLigneDel} toggle={toggleDelLig} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggleDelLig}
        >
          {props.t("Suppression tâche")}
        </div>
        <ModalBody style={{ direction: lng == "ar" ? "rtl" : "initial" }}>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>
                {props.t("Êtes-Vous sûr de vouloir supprimer cette tâche ?")}{" "}
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={delNewLigneDet}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleDelLig}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* supprimer ancienne tache */}
      <Modal isOpen={modalDelLigne} toggle={toggleDelAncLigne} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggleDelAncLigne}
        >
          {props.t("Suppression tâche")}
        </div>
        <ModalBody style={{ direction: lng == "ar" ? "rtl" : "initial" }}>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>
                {props.t("Êtes-Vous sûr de vouloir supprimer cette tâche ?")}
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={delAncLigneDet}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleDelAncLigneClose}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* supprimer tache */}
      <Modal isOpen={modalSuppTache} toggle={closeToggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={closeToggle}
        >
          {props.t("Suppression tâche")}
        </div>
        <ModalBody style={{ direction: lng == "ar" ? "rtl" : "initial" }}>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>
                {props.t("Êtes-Vous sûr de vouloir supprimer cette tâche ?")}
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={SuppTache}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={closeToggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(EditSuiviTache))
EditSuiviTache.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
