import axios from "axios"
if (localStorage.getItem("userAuthScolarite")) {
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var token = userAuthScolarite.user.api_token
}
export default axios.create({
  withCredentials: true,
  baseURL: `https://scolarite.backcresus-institut.ovh/api/`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
})
