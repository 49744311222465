import React, { useEffect, useState, useRef, Fragment } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import APIS from "../../apiS"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//i18n
import { withTranslation } from "react-i18next"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Container,
  ModalFooter,
  Label,
  Input,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import axios from "axios"
import { MetaTags } from "react-meta-tags"
const DataTableSoutenance = props => {
  var lng = localStorage.getItem("I18N_LANGUAGE")
  //
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  // detail soutenance
  const [etudiant, setEtudiant] = useState("")
  const [sujet, setSujet] = useState("")
  const [encadrant, setEncadrant] = useState("")
  const [chefJury, setChefJury] = useState("")
  const [rapporteur, setRapporteur] = useState("")
  const [date, setDate] = useState("")
  const [salle, setSalle] = useState("")
  const [materiel, setMateriel] = useState("")
  const [arraySout, setArraySout] = useState([])
  const [ensVolontaire, setEnsVolontaire] = useState("")
  // Data Table
  const [soutenance, setSoutenance] = useState([])
  //
  useEffect(async () => {
    var userAuthEntreprise = JSON.parse(
      localStorage.getItem("userAuthEntreprise")
    )
    var entrepriseId = userAuthEntreprise.user.id_entreprise
    const res = await API.post("soutenance/lists", {
      lang: lng,
      id: entrepriseId,
    }).then(res => {
      setSoutenance(res.data.Soutenance)
      setLoading(true)
    })
  }, [])
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: soutenance.length,
    page: 1,
    nextPageText: props.t("next"),
    prePageText: props.t("back"),
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: soutenance.length,
      },
    ],
  }
  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )
  const { SearchBar } = Search
  const Columns = () => [
    {
      dataField: "etudiant",
      text: props.t("Etudiant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "stage",
      text: props.t("Sujet"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "date_soutenance",
      text: props.t("Date"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Detail",
      isDummyField: true,
      text: props.t("Détail"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <i
            onClick={() => toggle(row.stage_id)}
            style={{
              color: "cornflowerblue",
              cursor: "pointer",
              fontSize: "15px",
            }}
            className="fas fa-info-circle"
          ></i>
        </div>
      ),
    },
    {
      dataField: "Etat soutenance",
      isDummyField: true,
      text: props.t("Etat soutenance"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={
              row.etat == 0
                ? { color: "orange", display: "flex" }
                : row.etat == 2
                ? { color: "red", display: "flex" }
                : { color: "green", display: "flex" }
            }
            className={
              row.etat == 0
                ? "fas fa-hourglass-half"
                : row.etat == 1
                ? "fas fa-check"
                : "fas fa-times"
            }
          >
            <p style={{ paddingLeft: "5px" }}>
              {row.etat == 0
                ? "En attente"
                : row.etat == 1
                ? "Valider"
                : "Refuser"}
            </p>
          </i>
        </div>
      ),
    },
  ]

  const toggle = id => {
    setModal(!modal)
    setId(id)
    const res = API.post("soutenance/get_by_id", {
      stage_id: id,
    }).then(res => {
      setLoad(true)
      setEtudiant(res.data.Soutenance.etudiant)
      setSujet(res.data.Soutenance.sujet)
      setEncadrant(res.data.Soutenance.encadrant)
      setChefJury(res.data.Soutenance.president)
      setRapporteur(res.data.Soutenance.rapporteur)
      setDate(res.data.Soutenance.date_soutenance)
      setSalle(res.data.Soutenance.salle)
      setMateriel(res.data.Soutenance.materiel)
      setArraySout(res.data.Soutenance.arrayJurySoutenance)
      setEnsVolontaire(res.data.Soutenance.ens_volontaire)
    })
  }

  const closeModal = () => {
    setModal(!modal)
    setLoad(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Etudiant")}</title>
        </MetaTags>
        <Container fluid>
          <div>
            {loading ? (
              <Row>
                <Col xs="12">
                  <ToolkitProvider
                    keyField="id"
                    data={soutenance}
                    columns={Columns()}
                    search
                    bootstrap4
                  >
                    {toolkitProps => (
                      <div>
                        <Col sm="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                            marginBottom: "10px",
                          }}
                        >
                          <button
                            type="button"
                            className="font-16 btn-block btn btn-primary btn btn-primary"
                          >
                            {props.t("Telecharger list soutenance")}
                            <i
                              style={{ marginLeft: "5px" }}
                              className="mdi mdi-file-document"
                            ></i>
                          </button>
                        </div> */}
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          noDataIndication={() => <NoDataIndication />}
                          striped={false}
                          bordered={false}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"table-light"}
                          hover
                          pagination={paginationFactory(pageOptions)}
                          {...toolkitProps.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </Col>
              </Row>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Spinner type="grow" className="ms-6" color="primary" />
                </div>
                <h4
                  style={{ textAlign: "center", marginTop: "2%" }}
                  className="ms-6"
                >
                  {" "}
                  {props.t("load_page")}
                </h4>
              </div>
            )}
          </div>
          <Modal
            isOpen={modal}
            toggle={closeModal}
            style={{
              maxWidth: "700px",
              width: "100%",
              direction: lng == "ar" ? "rtl" : "initial",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
            fade={false}
          >
            <div
              className="modal-header"
              style={{
                width: "100% !important",
                justifyContent: "center !important",
                margin: "0 auto",
                fontSize: "17px",
                display: "flex",
                alignItems: "baseline",
              }}
              toggle={toggle}
            >
              <p style={{ fontWeight: "bold", paddingRight: "5px" }}>
                {props.t("Détail soutenance")}
              </p>
            </div>
            <ModalBody>
              {load ? (
                arraySout.length > 0 ? (
                  <Fragment>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Etudiant")} :
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            value={etudiant}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Sujet")} :
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            value={sujet}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Encadrant")} :
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            value={encadrant}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Chef de jury")} :
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            value={chefJury}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Rapporteur")} :
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            value={rapporteur}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Date")} :
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            value={date}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Salle")} :
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            value={salle}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Etudiant")} :
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            value={etudiant}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Sujet")} :
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            value={sujet}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Jury")} :
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            value={ensVolontaire.label}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Date")} :
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            value={date}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Salle")} :
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            value={salle}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Fragment>
                )
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4 style={{ textAlign: "center" }} className="ms-6">
                    {props.t("load_page")}
                  </h4>
                </div>
              )}
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(DataTableSoutenance))
DataTableSoutenance.propTypes = {
  soutenance: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
