import React, { useState, useEffect, useMemo } from "react"
import { ToastContainer, toast } from "react-toastify"
import Select from "react-select"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { Redirect, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import API from "../../api"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import APIS from "../../apiS"

const AddOffreEmplois = props => {
  const lng = localStorage.getItem("I18N_LANGUAGE")
  const [datePub, setDatePub] = useState(new Date())
  const [intitulePost, setIntitulePost] = useState("")
  const [description, setDescription] = useState("")
  const [nbrPost, setNbrPoste] = useState("")
  const [adresse, setAdresse] = useState("")
  const [arrayTypeContrat, setArrayTypeContrat] = useState([])
  const [typeContrat, setTypeContrat] = useState("")
  const [experience, setExperience] = useState("")
  const [motsCle, setMotsCle] = useState("")
  const [dateExp, setDateExp] = useState(new Date())
  const [langue, setLangue] = useState("")

  useEffect(async () => {
    const resG = await APIS.post("type_contrat/select", {
      lang: lng,
    }).then(resG => {
      setArrayTypeContrat(resG.data.Type_contrat)
    })
  }, [])

  const save = async () => {
    var begin = datePub.getTime() / 1000
    var end = dateExp.getTime() / 1000
    if (end > begin) {
      if (experience != "") {
        // convert date publication
        let dateOne = datePub
        let monthOne = "" + (dateOne.getMonth() + 1)
        let dayOne = "" + dateOne.getDate()
        let yearOne = dateOne.getFullYear()
        let convertDatePub = [yearOne, monthOne, dayOne].join("-")
        //
        // convert date expiration
        let dateTwo = dateExp
        let monthTwo = "" + (dateTwo.getMonth() + 1)
        let dayTwo = "" + dateTwo.getDate()
        let yearTwo = dateTwo.getFullYear()
        let convertDateExp = [yearTwo, monthTwo, dayTwo].join("-")
        //
        var userAuth = JSON.parse(localStorage.getItem("userAuthStage"))
        var entrepriseId = userAuth.user.id_entreprise
        const res = await APIS.post("offre_emploi/add", {
          lange: langue,
          entreprise_id: entrepriseId,
          date_pub: convertDatePub,
          intitule_postulation: intitulePost,
          description: description,
          nombre_place: nbrPost,
          adresse: adresse,
          type_contrat_id: typeContrat.value,
          experience: experience,
          mot_cle: motsCle,
          date_expiration: convertDateExp,
        })
          .then(res => {
            props.setSection(1)
          })
          .catch(() => {
            toast.error("⛔  Veuillez vérifier tous les champs !", {
              containerId: "A",
            })
          })
      } else {
        toast.error("⛔ Veuillez vérifier tous les champs !", {
          containerId: "A",
        })
      }
    } else {
      toast.error("Date fin doit étre supérieur a la date debut", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Date de publication")}
                </Label>
                <DatePicker
                  name="Date de publication"
                  selected={datePub}
                  className="form-control ddate"
                  dateFormat="dd/MM/yyyy"
                  onChange={setDatePub}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Date d'expiration")}
                </Label>
                <DatePicker
                  name="Date d'expiration"
                  selected={dateExp}
                  className="form-control ddate"
                  dateFormat="dd/MM/yyyy"
                  onChange={setDateExp}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Intitulé de poste")}
                </Label>
                <Input
                  name="Intitulé de poste"
                  lg="3"
                  className="form-control"
                  placeholder={props.t("Entrer intitulé de poste")}
                  type="text"
                  onChange={e => setIntitulePost(e.target.value)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Nombre de postes")}
                </Label>
                <Input
                  name="Nombre de postes"
                  lg="3"
                  min="1"
                  className="form-control"
                  placeholder={props.t("Entrer nombre de postes")}
                  type="number"
                  onChange={e => setNbrPoste(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {" "}
                  {props.t("Adresse")}
                </Label>
                <Input
                  name="Adresse"
                  lg="3"
                  className="form-control"
                  placeholder={props.t("Entrer adresse")}
                  type="text"
                  onChange={e => setAdresse(e.target.value)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Type de Contrat")}
                </Label>
                <Select
                  name="Type de Contrat"
                  options={arrayTypeContrat}
                  isSearchable={true}
                  onChange={setTypeContrat}
                  placeholder={props.t("Entrer type de contrat")}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {" "}
                  {props.t("Expérience")}
                </Label>
                <Input
                  name="Expérience"
                  lg="3"
                  min="0"
                  className="form-control"
                  placeholder={props.t(
                    "Entrer expérience exprimée en nomber d'année"
                  )}
                  type="number"
                  onChange={e => setExperience(e.target.value)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {" "}
                  {props.t("Mots Clé")}
                </Label>
                <Input
                  name="Mots Clé"
                  lg="3"
                  className="form-control"
                  placeholder={props.t("Entrer mot Clé")}
                  type="text"
                  onChange={e => setMotsCle(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Langues")}
                </Label>
                <Input
                  name="Langues"
                  lg="3"
                  className="form-control"
                  placeholder={props.t("Entrer langues")}
                  type="text"
                  onChange={e => setLangue(e.target.value)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Description")}
                </Label>
                <Input
                  name="Description"
                  lg="3"
                  className="form-control"
                  placeholder={props.t("Entrer description")}
                  type="text"
                  onChange={e => setDescription(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            direction: lng == "ar" ? "initial" : "initial",
          }}
        >
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                name="Annuler"
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                {props.t("Annuler")}
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button
                name="Confirmer"
                type="button"
                className="btn btn-primary "
                onClick={save}
              >
                {props.t("Confirmer")}
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(AddOffreEmplois))
AddOffreEmplois.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
