import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import APIS from "../../apiS"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Container,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import axios from "axios"
import { withTranslation } from "react-i18next"
import APID from "../../apiD"

const Soutenance = props => {
  // lang
  var lng = localStorage.getItem("I18N_LANGUAGE")
  //
  const [loading, setLoading] = useState(false)
  const [sujetId, setSujetId] = useState("")
  const [date, setDate] = useState(new Date())
  const [heureDebut, setHeureDebut] = useState(new Date())
  const [heureFin, setHeureFin] = useState(new Date())
  const [listSalle, setListSalle] = useState([{ label: "Salle 1", value: 1 }])
  const [salle, setSalle] = useState("")
  const [listMateriel, setListMateriel] = useState([])
  const [materiel, setMateriel] = useState("")
  const [listEns, setListEns] = useState([])
  const [listTypeEns, setListTypeEns] = useState([])
  const [arrayEns, setArrayEns] = useState([{ jury: "", type_jury: "" }])
  const [modal, setModal] = useState(false)
  const [modalEns, setModalEns] = useState(false)
  const [indexLigne, setIndexLigne] = useState("")
  // new jury
  const [newNom, setNewNom] = useState("")
  const [newPrenom, setNewPrenom] = useState("")
  const [newTel, setNewTel] = useState("")
  const [newEmail, setNewEmail] = useState("")
  //
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setSujetId(id)
    //
    const res = await API.post("soutenance/get_by_id", { stage_id: id }).then(
      res => {
        if (res.data.Soutenance.date != null) {
          setDate(new Date(res.data.Soutenance.date))
          setHeureDebut(new Date(res.data.Soutenance.heure_debut))
          setHeureFin(new Date(res.data.Soutenance.heure_fin))
          setSalle(res.data.Soutenance.arrySalle)
          setMateriel(res.data.Soutenance.arryMateriel)
          setArrayEns(res.data.Soutenance.arrayJurySoutenance)
          setLoading(true)
        } else {
          setLoading(true)
        }
      }
    )
    // salle
    const resS = await APID.get("list_salle", { lang: lng }).then(resS => {
      setListSalle(resS.data.Salle)
    })
    // materiel
    const resA = await APID.post("ressource/select").then(resA => {
      setListMateriel(resA.data.Materiel)
    })
    // jury
    const resN = await API.get("jury/list_jury_select").then(resN => {
      setListEns(resN.data.jurys)
    })
    // type
    const resC = await API.post("type_jury/select").then(resA => {
      setListTypeEns(resA.data.Type)
    })
  }, [])

  const addLigne = () => {
    let element = { jury: "", type_jury: "" }
    setArrayEns([...arrayEns, element])
  }

  const toggle = indexOfLigne => {
    setModal(!modal)
    setIndexLigne(indexOfLigne)
  }

  const toggleDelete = async () => {
    arrayEns.splice(indexLigne, 1)
    setModal(false)
  }

  const addEns = (event, index) => {
    let jury = event
    setArrayEns(
      arrayEns.map((el, id) =>
        id === index ? Object.assign(el, { jury: jury }) : el
      )
    )
  }

  const addTypeEns = (event, index) => {
    let type_jury = event
    setArrayEns(
      arrayEns.map((el, id) =>
        id === index ? Object.assign(el, { type_jury: type_jury }) : el
      )
    )
  }

  const toggleEns = index => {
    setModalEns(!modalEns)
  }

  const saveJury = async () => {
    const res = await API.post("jury/add_jury", {
      firstName: newPrenom,
      lastName: newNom,
      telephone: newTel,
      email: newEmail,
    })
      .then(res => {
        setModalEns(!modalEns)
        const resN = API.get("jury/list_jury_select").then(resN => {
          setListEns(resN.data.jurys)
        })
      })
      .catch(() => {
        toast.error("⛔", {
          containerId: "A",
        })
      })
  }

  const save = async () => {
    // convert date seance
    let dateSout = date
    let month = "" + (dateSout.getMonth() + 1)
    let day = "" + dateSout.getDate()
    let year = dateSout.getFullYear()
    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day
    let convertDate = [year, month, day].join("-")
    // convert heure debut
    let hd = heureDebut
    let hoursHd = hd.getHours()
    let minutesHd = hd.getMinutes()
    minutesHd = minutesHd.toString()
    if (minutesHd.length < 2) {
      if (minutesHd == "0") {
        minutesHd = "00"
      } else {
        minutesHd.concat("", "0")
      }
    }
    let secondsHd = hd.getSeconds()
    secondsHd = secondsHd.toString()
    if (secondsHd.length < 2) {
      if (secondsHd == "0") {
        secondsHd = "00"
      } else {
        secondsHd.concat("", "0")
      }
    }
    let convertHd =
      convertDate + " " + hoursHd + ":" + minutesHd + ":" + secondsHd
    // convert heure fin
    let hf = heureFin
    let hoursHf = hf.getHours()
    let minutesHf = hf.getMinutes()
    minutesHf = minutesHf.toString()
    if (minutesHf.length < 2) {
      if (minutesHf == "0") {
        minutesHf = "00"
      } else {
        minutesHf.concat("", "0")
      }
    }
    let secondsHf = hf.getSeconds()
    secondsHf = secondsHf.toString()
    if (secondsHf.length < 2) {
      if (secondsHf == "0") {
        secondsHf = "00"
      } else {
        secondsHf.concat("", "0")
      }
    }
    let convertHf =
      convertDate + " " + hoursHf + ":" + minutesHf + ":" + secondsHf
    //
    const res = await API.post("soutenance/add", {
      sujet_id: sujetId,
      date: convertDate,
      heureDebut: convertHd,
      heureFin: convertHf,
      salle: salle,
      materiel: materiel,
      arrayEns: arrayEns,
    })
      .then(res => {
        props.history.push("/AffectStage")
      })
      .catch(() => {
        toast.error("Probléme lors de l'insertion", {
          containerId: "A",
        })
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading ? (
          <Container fluid={true}>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mt-4">
                    {props.t("Ajout soutenance")}
                  </CardTitle>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Date")} :{" "}
                          </Label>
                          <DatePicker
                            selected={date}
                            className="form-control ddate"
                            dateFormat="dd/MM/yyyy"
                            onChange={setDate}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Heure debut")}{" "}
                          </Label>
                          <DatePicker
                            className="form-control ddate"
                            style={{ border: "none" }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            onChange={setHeureDebut}
                            selected={heureDebut}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Heure fin")}{" "}
                          </Label>
                          <DatePicker
                            className="form-control ddate"
                            style={{ border: "none" }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            onChange={setHeureFin}
                            selected={heureFin}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Salle")}{" "}
                          </Label>
                          <Select
                            options={listSalle}
                            isSearchable={true}
                            onChange={setSalle}
                            placeholder={props.t("Select")}
                            value={salle}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Matériel")}{" "}
                          </Label>
                          <Select
                            options={listMateriel}
                            isSearchable={true}
                            onChange={setMateriel}
                            placeholder={props.t("Select")}
                            isMulti={true}
                            value={materiel}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <table className="table table-bordered mt-4">
                    <thead>
                      <tr>
                        <th
                          style={{ textAlign: "center" }}
                          className="col-md-5"
                          scope="col"
                        >
                          {props.t("Jury")}
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="col-md-5"
                          scope="col"
                        >
                          {props.t("Type")}
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="col-md-2"
                          scope="col"
                        >
                          <i
                            style={{
                              color: "#a0ceb8",
                              cursor: "pointer",
                            }}
                            className="fas fa-plus"
                            onClick={addLigne}
                          />
                        </th>
                      </tr>
                    </thead>
                    {arrayEns.map((el, index) => (
                      <tbody key={index}>
                        <tr>
                          <td className="col-md-5">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around",
                              }}
                            >
                              <div style={{ width: "95%" }}>
                                <Select
                                  options={listEns}
                                  isSearchable={true}
                                  onChange={e => addEns(e, index)}
                                  placeholder={props.t("Select")}
                                  value={el.jury}
                                />
                              </div>
                              <div style={{ width: "3%" }}>
                                <button
                                  type="button"
                                  className="btn btn-light position-relative p-0 avatar-xs rounded-circle"
                                  style={{ backgroundColor: "cornflowerblue" }}
                                >
                                  <span
                                    onClick={toggleEns}
                                    className="avatar-title bg-transparent text-reset"
                                  >
                                    <i
                                      style={{ color: "white" }}
                                      className="fas fa-plus"
                                    ></i>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </td>
                          <td className="col-md-5">
                            <Select
                              options={listTypeEns}
                              isSearchable={true}
                              onChange={e => addTypeEns(e, index)}
                              placeholder={props.t("Select")}
                              value={el.type_jury}
                            />
                          </td>
                          <td
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                          >
                            <i
                              style={{ color: "#cc0000" }}
                              className="mdi mdi-delete font-size-18"
                              id="deletetooltip"
                              onClick={() => toggle(index)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      direction: lng == "ar" ? "initial" : "initial",
                    }}
                  >
                    {" "}
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                        >
                          {props.t("Annuler")}
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={save}
                        >
                          {props.t("Confirmer")}
                        </button>
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Row>
            <ToastContainer
              transition={Slide}
              enableMultiContainer
              containerId={"A"}
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
            />
            {/* <> */}
            <Modal
              style={{
                maxWidth: "50%",
                width: "100%",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
              isOpen={modalEns}
              toggle={toggleEns}
              centered={true}
            >
              <div
                className="modal-header"
                style={{
                  width: "100% !important",
                  justifyContent: "center !important",
                  direction: lng == "ar" ? "rtl" : "initial",
                  margin: "0 auto",
                  fontSize: "17px",
                }}
                toggle={toggleEns}
              >
                {props.t("Ajout nouveau jury")}
              </div>
              <ModalBody style={{ direction: lng == "ar" ? "rtl" : "initial" }}>
                <Form>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          {props.t("Nom")} :{" "}
                        </Label>
                        <Input
                          lg="3"
                          className="form-control"
                          placeholder={props.t("Nom")}
                          type="text"
                          onChange={e => setNewNom(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          {props.t("Prénom")}{" "}
                        </Label>
                        <Input
                          lg="3"
                          className="form-control"
                          placeholder={props.t("Prénom")}
                          type="text"
                          onChange={e => setNewPrenom(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          {props.t("Tel")}
                        </Label>
                        <Input
                          lg="3"
                          className="form-control"
                          placeholder={props.t("Tel")}
                          type="text"
                          onChange={e => setNewTel(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          {props.t("Email")}
                        </Label>
                        <Input
                          lg="3"
                          className="form-control"
                          placeholder={props.t("Email")}
                          type="text"
                          onChange={e => setNewEmail(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    direction: lng == "ar" ? "initial" : "initial",
                  }}
                >
                  {" "}
                  <Col lg="6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      className="text-center mt-4"
                    >
                      <Button
                        type="button"
                        color="warning"
                        className="btn btn-warning  mb-2 me-2"
                        onClick={toggleEns}
                      >
                        {props.t("Annuler")}
                      </Button>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      className="text-center mt-4"
                    >
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={saveJury}
                      >
                        {props.t("Confirmer")}
                      </button>
                    </div>
                  </Col>
                </div>
              </ModalBody>
            </Modal>
            {/* <> */}
            <Modal isOpen={modal} toggle={toggle} centered={true}>
              <div
                className="modal-header"
                style={{
                  width: "100% !important",
                  justifyContent: "center !important",
                  margin: "0 auto",
                  fontSize: "17px",
                }}
                toggle={toggle}
              >
                {props.t("Suppression ligne")}
              </div>
              <ModalBody>
                <div
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    FontSize: "14px",
                    FontWeight: "700",
                    LineHeight: "18.375px",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p>
                      {props.t(
                        "Êtes-Vous sûr de vouloir supprimer cette ligne ?"
                      )}
                    </p>
                  </div>
                  <div
                    className="hvr-push"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginBottom: "15px",
                      justifyContent: "center",
                      direction: lng == "ar" ? "initial" : "initial",
                    }}
                  >
                    <div>
                      <Button
                        onClick={toggleDelete}
                        color="info"
                        className="btn-rounded "
                      >
                        <i
                          style={{ color: "white" }}
                          className="far fa-thumbs-up"
                        ></i>
                        {props.t("oui")}
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={toggle}
                        color="danger"
                        className="btn-rounded "
                      >
                        <i
                          style={{ color: "white" }}
                          className="far fa-thumbs-down"
                        ></i>
                        {props.t("non")}
                      </Button>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("load_page")}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Soutenance)
Soutenance.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
