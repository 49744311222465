import React, { useEffect, useState, useRef, Fragment } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import APIS from "../../apiS"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//i18n
import { withTranslation } from "react-i18next"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Container,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { MetaTags } from "react-meta-tags"

const DataTableCandidature = props => {
  var lng = localStorage.getItem("I18N_LANGUAGE")
  //
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [id, setId] = useState("")
  const [modal, setModal] = useState(false)
  // detail candidature
  const [intitPoste, setIntitPoste] = useState("")
  const [nbrTotal, setNbrTotal] = useState("")
  const [nbrAttente, setNbrAttente] = useState("")
  const [nbrValider, setValider] = useState("")
  const [nbrRejeter, setNbrRejeter] = useState("")
  // Data Table
  const [offreEmploi, setOffreEmploi] = useState([])
  useEffect(async () => {
    const res = await APIS.post("offre_emploi/list", {
      lang: lng,
    }).then(res => {
      setOffreEmploi(res.data.Emploi)
    })
    setLoading(true)
  }, [])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: offreEmploi.length,
    page: 1,
    nextPageText: props.t("next"),
    prePageText: props.t("back"),
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: offreEmploi.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )

  const { SearchBar } = Search

  const Columns = () => [
    {
      dataField: "date_publication",
      text: props.t("Date de publication"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "intitule_postulation",
      text: props.t("Intitule du poste"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "type_contrat",
      text: props.t("Type de contrat"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "date_expiration",
      text: props.t("Date d'expiration"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Detail postulation",
      isDummyField: true,
      text: props.t("Détail postulation"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div
          style={{ display: "flex", justifyContent: "center" }}
          onClick={() => toggle(row.id)}
        >
          <i style={{ cursor: "pointer" }} className="fas fa-info-circle"></i>
        </div>
      ),
    },
    {
      dataField: "Candidatures",
      isDummyField: true,
      text: props.t("Candidatures"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to={"/EditCandidature?id=" + row.id}>
            <i
              style={{
                color: "cornflowerblue",
                cursor: "pointer",
                fontSize: "20px",
              }}
              className="fas fa-tasks"
            ></i>
          </Link>
        </div>
      ),
    },
  ]

  const toggle = id => {
    setModal(!modal)
    const res = APIS.post("offre_emploi/getById", { id }).then(res => {
      if (res.data.status === 200) {
        // detail candidature
        setIntitPoste(res.data.Emploi.intitule_postulation)
        setNbrTotal(res.data.Emploi.countTotal)
        setNbrAttente(res.data.Emploi.countEnAttente)
        setValider(res.data.Emploi.countValider)
        setNbrRejeter(res.data.Emploi.countRefuser)
        setLoad(true)
      }
    })
  }

  const closeModal = () => {
    setModal(!modal)
    setLoad(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Candidatures")}</title>
        </MetaTags>
        <Container fluid>
          <div>
            {loading ? (
              <Row>
                <Col xs="12">
                  <ToolkitProvider
                    keyField="id"
                    data={offreEmploi}
                    columns={Columns()}
                    search
                    bootstrap4
                  >
                    {toolkitProps => (
                      <div>
                        <Col sm="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          noDataIndication={() => <NoDataIndication />}
                          striped={false}
                          bordered={false}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"table-light"}
                          hover
                          pagination={paginationFactory(pageOptions)}
                          {...toolkitProps.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </Col>
              </Row>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Spinner type="grow" className="ms-6" color="primary" />
                </div>
                <h4
                  style={{ textAlign: "center", marginTop: "2%" }}
                  className="ms-6"
                >
                  {props.t("load_page")}
                </h4>
              </div>
            )}
          </div>
        </Container>
      </div>
      {/* <> */}
      <Modal isOpen={modal} toggle={closeModal} centered={true}>
        <Fragment>
          <ModalBody>
            <div className="row">
              <div className="col-xl-12">
                <div
                  className="text-center p-4 border-end"
                  style={{ direction: lng == "ar" ? "rtl" : "initial" }}
                >
                  <h5
                    className="text-truncate pb-1"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    {props.t("Intitulé du poste")}
                    {" : " + intitPoste}
                  </h5>
                </div>
              </div>
              {load ? (
                <div className="col-xl-12">
                  <div className="row">
                    <div className="col-3">
                      <div>
                        <p
                          style={{ textAlign: "center" }}
                          className="text-muted mb-2 text-truncate"
                        >
                          {props.t("Nombre total")}
                        </p>
                        <h5 style={{ textAlign: "center" }}>{nbrTotal}</h5>
                      </div>
                    </div>
                    <div className="col-3">
                      <div>
                        <p
                          style={{ textAlign: "center" }}
                          className="text-muted mb-2 text-truncate"
                        >
                          {props.t("En attente")}
                        </p>
                        <h5 style={{ color: "yellow", textAlign: "center" }}>
                          {nbrAttente}
                        </h5>
                      </div>
                    </div>
                    <div className="col-3">
                      <div>
                        <p
                          style={{ textAlign: "center" }}
                          className="text-muted mb-2 text-truncate"
                        >
                          {props.t("Accepter")}
                        </p>
                        <h5 style={{ color: "green", textAlign: "center" }}>
                          {nbrValider}
                        </h5>
                      </div>
                    </div>
                    <div className="col-3">
                      <div>
                        <p
                          style={{ textAlign: "center" }}
                          className="text-muted mb-2 text-truncate"
                        >
                          {props.t("Rejeter")}
                        </p>
                        <h5 style={{ color: "red", textAlign: "center" }}>
                          {nbrRejeter}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {props.t("load_page")}
                  </h4>
                </div>
              )}
            </div>
          </ModalBody>
        </Fragment>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableCandidature))
DataTableCandidature.propTypes = {
  offreEmploi: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
