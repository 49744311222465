import React, { useState, useEffect, useMemo } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import { Redirect, withRouter } from "react-router-dom"
import PropTypes from "prop-types"

import API from "../../api"
//i18n
import { withTranslation } from "react-i18next"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap"
import APIS from "../../apiS"

const EditOffreEmplois = props => {
  const lng = localStorage.getItem("I18N_LANGUAGE")
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState("")
  const [datePub, setDatePub] = useState(new Date())
  const [intitulePost, setIntitulePost] = useState("")
  const [description, setDescription] = useState("")
  const [nbrPost, setNbrPoste] = useState("")
  const [adresse, setAdresse] = useState("")
  const [arrayTypeContrat, setArrayTypeContrat] = useState([])
  const [typeContrat, setTypeContrat] = useState("")
  const [experience, setExperience] = useState("")
  const [motsCle, setMotsCle] = useState("")
  const [dateExp, setDateExp] = useState(new Date())
  const [langue, setLangue] = useState("")
  const [modal, setModal] = useState(false)

  useEffect(async () => {
    var lang = localStorage.getItem("I18N_LANGUAGE")
    const resG = await APIS.post("type_contrat/select", {
      lang: lang,
    }).then(resG => {
      setArrayTypeContrat(resG.data.Type_contrat)
    })
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await APIS.post("offre_emploi/getById", { id })
    if (res.data.status === 200) {
      setLoading(true)
      setId(id)
      setDatePub(new Date(res.data.Emploi.date_publication))
      setIntitulePost(res.data.Emploi.intitule_postulation)
      setDescription(res.data.Emploi.description)
      setNbrPoste(res.data.Emploi.nombre)
      setAdresse(res.data.Emploi.adresse)
      setExperience(res.data.Emploi.experience_requise)
      setMotsCle(res.data.Emploi.mot_cle)
      setDateExp(new Date(res.data.Emploi.date_expiration))
      setTypeContrat(res.data.Emploi.type_contrat)
      setLangue(res.data.Emploi.langue)
    }
  }, [])

  const toggleDelete = async () => {
    const res = await APIS.post("offre_emploi/delete", { id }).then(res => {
      setModal(!modal)
      props.history.push("/OffreEmplois")
    })
  }
  const toggle = () => {
    setModal(!modal)
  }

  const edit = async () => {
    var begin = datePub.getTime() / 1000
    var end = dateExp.getTime() / 1000
    if (end > begin) {
      // convert date publication
      let dateOne = datePub
      let monthOne = "" + (dateOne.getMonth() + 1)
      let dayOne = "" + dateOne.getDate()
      let yearOne = dateOne.getFullYear()
      let convertDatePub = [yearOne, monthOne, dayOne].join("-")
      //
      // convert date expiration
      let dateTwo = dateExp
      let monthTwo = "" + (dateTwo.getMonth() + 1)
      let dayTwo = "" + dateTwo.getDate()
      let yearTwo = dateTwo.getFullYear()
      let convertDateExp = [yearTwo, monthTwo, dayTwo].join("-")
      //
      var userAuth = JSON.parse(localStorage.getItem("userAuthEntreprise"))
      var entrepriseId = userAuth.user.id_entreprise
      const res = await APIS.post("offre_emploi/edit", {
        id: id,
        entreprise_id: entrepriseId,
        date_pub: convertDatePub,
        intitule_postulation: intitulePost,
        description: description,
        nombre_place: nbrPost,
        adresse: adresse,
        type_contrat_id: typeContrat.value,
        experience: experience,
        mot_cle: motsCle,
        date_expiration: convertDateExp,
        lange: langue,
      })
        .then(res => {
          props.history.push("/OffreEmplois")
        })
        .catch(() => {
          toast.error(":sens_interdit:  Veuillez vérifier tous les champs !", {
            containerId: "A",
          })
        })
    } else {
      toast.error("Date fin doit étre supérieur a la date debut", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      {loading ? (
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4" style={{ color: "#556ee6" }}>
                    {props.t("Modification offre d'emploi")}
                  </CardTitle>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Date de publication")}
                          </Label>
                          <DatePicker
                            name="Date de publication"
                            selected={datePub}
                            className="form-control ddate"
                            dateFormat="dd/MM/yyyy"
                            onChange={setDatePub}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Date d'expiration")}
                          </Label>
                          <DatePicker
                            name="Date d'expiration"
                            selected={dateExp}
                            className="form-control ddate"
                            dateFormat="dd/MM/yyyy"
                            onChange={setDateExp}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Intitulé de poste")}
                          </Label>
                          <Input
                            name="Intitulé de poste"
                            lg="3"
                            className="form-control"
                            value={intitulePost}
                            type="text"
                            onChange={e => setIntitulePost(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Nombre de poste")}
                          </Label>
                          <Input
                            name="Nombre de poste"
                            lg="3"
                            min="1"
                            className="form-control"
                            value={nbrPost}
                            type="number"
                            onChange={e => setNbrPoste(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Adresse")}
                          </Label>
                          <Input
                            name="Adresse"
                            lg="3"
                            className="form-control"
                            value={adresse}
                            type="text"
                            onChange={e => setAdresse(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Type de Contrat")}
                          </Label>
                          <Select
                            name="Type de Contrat"
                            options={arrayTypeContrat}
                            isSearchable={true}
                            onChange={setTypeContrat}
                            value={typeContrat}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Experience")}
                          </Label>
                          <Input
                            name="Experience"
                            lg="3"
                            min="0"
                            className="form-control"
                            value={experience}
                            type="number"
                            onChange={e => setExperience(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Mots Clé")}
                          </Label>
                          <Input
                            name="Mots Clé"
                            lg="3"
                            className="form-control"
                            value={motsCle}
                            type="text"
                            onChange={e => setMotsCle(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Langues")}
                          </Label>
                          <Input
                            name="Langues"
                            lg="3"
                            className="form-control"
                            value={langue}
                            type="text"
                            onChange={e => setLangue(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Description")}
                          </Label>
                          <Input
                            name="Description"
                            lg="3"
                            className="form-control"
                            value={description}
                            type="text"
                            onChange={e => setDescription(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      direction: lng == "ar" ? "initial" : "initial",
                    }}
                  >
                    <div lg="4">
                      <Button
                        name="Supprimer"
                        type="button"
                        color="danger"
                        className="btn btn-primary  mb-2 me-2"
                        onClick={toggle}
                      >
                        {props.t("Supprimer")}
                      </Button>
                    </div>
                    <div lg="4">
                      <Button
                        name="Annuler"
                        type="button"
                        color="warning"
                        className="btn btn-warning  mb-2 me-2"
                        onClick={() => props.history.push("/OffreEmplois")}
                      >
                        {props.t("Annuler")}
                      </Button>
                    </div>
                    <div lg="4">
                      <button
                        name="Confirmer"
                        type="button"
                        className="btn btn-primary "
                        onClick={edit}
                      >
                        {props.t("Confirmer")}
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>
      ) : (
        <div style={{ marginTop: "8%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {" "}
            {props.t("load_page")}
          </h4>
        </div>
      )}
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggle}
        >
          {props.t("Suppression offre d'emploi")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>
                {props.t("Êtes-Vous sûr de vouloir supprimer cette offre ?")}
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  name="Oui"
                  color="info"
                  className="btn-rounded "
                  onClick={toggleDelete}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  name="Non"
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(EditOffreEmplois))
EditOffreEmplois.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
