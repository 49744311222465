import React, { useState, useEffect, useMemo } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import { Redirect, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import API from "../../api"
//Import Date Picker
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import APIS from "../../apiS"

const EditCandidature = props => {
  const lng = localStorage.getItem("I18N_LANGUAGE")
  const [loading, setLoading] = useState(false)
  const [arrayCandidature, setArrayCandidature] = useState([])
  const [id, setId] = useState(false)
  const [file, setFile] = useState("")
  //
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await APIS.post("postulation/getById", {
      annee: 2022,
      entite: "postulation",
      id: id
    })
    if (res.data.status === 200) {
      setId(id)
      setArrayCandidature(res.data.Candidature)
    }
    // file
    // const resD = await APIS.post("file/list", {
    //   annee: "2022",
    //   entite: "import",
    //   id: 1,
    //   type: "document",
    // })
    // if (resD.data.status === 200) {
    //   setFile(resD.data.File[0].filename)
    // }
    //
    setLoading(true)
  }, [])

  const editEtat = (e, val, index) => {
    var bolCheck = e.target.checked
    if (bolCheck == true) {
      var etat = 1
    } else {
      var etat = 0
    }
    if (val == "annuler") {
      setArrayCandidature(
        arrayCandidature.map((el, id) =>
          id === index
            ? Object.assign(el, {
                etatAnnuler: etat,
                etatAccepter: 0,
                etatRefuser: 0,
              })
            : el
        )
      )
    } else if (val == "accepter") {
      setArrayCandidature(
        arrayCandidature.map((el, id) =>
          id === index
            ? Object.assign(el, {
                etatAnnuler: 0,
                etatAccepter: etat,
                etatRefuser: 0,
              })
            : el
        )
      )
    } else if (val == "refuser") {
      setArrayCandidature(
        arrayCandidature.map((el, id) =>
          id === index
            ? Object.assign(el, {
                etatAnnuler: 0,
                etatAccepter: 0,
                etatRefuser: etat,
              })
            : el
        )
      )
    }
  }

  const edit = async () => {
    const res = await APIS.post("postulation/update_etat", {
      candidatures: arrayCandidature,
    })
      .then(res => {
        props.history.push("/Candidature")
      })
      .catch(() => {
        toast.error(":sens_interdit: Veuillez vérifier tous les champs !", {
          containerId: "A",
        })
      })
  }

  return (
    <React.Fragment>
      {loading ? (
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle className="h4" style={{ color: "#556ee6" }}>
                    {props.t("Mise à jour candidature")}
                  </CardTitle>
                  {/* <> */}
                  {/* <body>
                    <a href="" download={file}>
                      <button type="button">Download</button>
                    </a>
                  </body> */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <div className="react-bootstrap-table">
                              <table className="table table align-middle table-nowrap table-check">
                                <thead className="table-light">
                                  <tr>
                                    <th
                                      className="col-md-2"
                                      style={{ textAlign: "center" }}
                                    >
                                      {props.t("Etudiant")}
                                    </th>
                                    <th
                                      className="col-md-2"
                                      style={{ textAlign: "center" }}
                                    >
                                      {props.t("Date candidature")}
                                    </th>
                                    <th
                                      className="col-md-2"
                                      style={{ textAlign: "center" }}
                                    >
                                      {props.t("Cv étudiant")}
                                    </th>
                                    <th
                                      className="col-md-2"
                                      style={{ textAlign: "center" }}
                                    >
                                      {props.t("En attente")}{" "}
                                      <i
                                        className="fas fa-hourglass-half"
                                        style={{
                                          fontSize: "15px",
                                          paddingLeft: "5px",
                                          color: "goldenrod",
                                        }}
                                      />
                                    </th>
                                    <th
                                      className="col-md-2"
                                      style={{ textAlign: "center" }}
                                    >
                                      {props.t("Accepter")}{" "}
                                      <i
                                        className="fas fa-check-circle"
                                        style={{
                                          fontSize: "15px",
                                          paddingLeft: "5px",
                                          color: "darkseagreen",
                                        }}
                                      ></i>
                                    </th>
                                    <th
                                      className="col-md-2"
                                      style={{ textAlign: "center" }}
                                    >
                                      {props.t("Refuser")}{" "}
                                      <i
                                        className="fas fa-times-circle"
                                        style={{
                                          fontSize: "15px",
                                          paddingLeft: "5px",
                                          color: "tomato",
                                        }}
                                      ></i>
                                    </th>
                                  </tr>
                                </thead>
                                {arrayCandidature.map((el, index) => (
                                  <tbody key={index}>
                                    <tr>
                                      <td
                                        className="col-md-2"
                                        style={{ textAlign: "center" }}
                                      >
                                        {el.etudiant}
                                      </td>
                                      <td
                                        className="col-md-2"
                                        style={{ textAlign: "center" }}
                                      >
                                        {el.date}
                                      </td>
                                      <td
                                        className="col-md-2"
                                        style={{ textAlign: "center" }}
                                      >
                                        <i
                                          style={{ cursor: "pointer" }}
                                          className="fas fa-file-pdf"
                                        />
                                      </td>
                                      <td
                                        className="col-md-2"
                                        style={{ textAlign: "center" }}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={el.etatAnnuler}
                                          onChange={e =>
                                            editEtat(e, "annuler", index)
                                          }
                                        />
                                      </td>
                                      <td
                                        className="col-md-2"
                                        style={{ textAlign: "center" }}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={el.etatAccepter}
                                          onChange={e =>
                                            editEtat(e, "accepter", index)
                                          }
                                        />
                                      </td>
                                      <td
                                        className="col-md-2"
                                        style={{ textAlign: "center" }}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={el.etatRefuser}
                                          onChange={e =>
                                            editEtat(e, "refuser", index)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <> */}
                  {arrayCandidature.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        direction: lng == "ar" ? "initial" : "initial",
                      }}
                    >
                      {" "}
                      <di lg="6">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          className="text-center mt-4"
                        >
                          <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning  mb-2 me-2"
                            onClick={() => props.history.push("/Candidature")}
                          >
                            {props.t("Annuler")}
                          </Button>
                        </div>
                      </di>
                      <div lg="6">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          className="text-center mt-4"
                        >
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={edit}
                          >
                            {props.t("Confirmer")}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Row>
          </Container>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </div>
      ) : (
        <div style={{ marginTop: "8%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {" "}
            {props.t("load_page")}
          </h4>
        </div>
      )}
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(EditCandidature))
EditCandidature.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
