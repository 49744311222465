import React, { useState, useEffect, useMemo } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import { Redirect, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import API from "../../api"
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Date Picker
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import APID from "../../apiD"

const AddOffreStage = props => {
  const lng = localStorage.getItem("I18N_LANGUAGE")
  const [periode, setPeriode] = useState("")
  const [description, setDescription] = useState("")
  const [sujet, setSujet] = useState("")
  const [arrayType, setArrayType] = useState([])
  const [type, setType] = useState([])
  const [arraySpec, setArraySpec] = useState([])
  const [specialite, setSpecialite] = useState([])
  const [arrayNiveau, setArrayNiveau] = useState([])
  const [niveau, setNiveau] = useState([])
  const [nom, setNom] = useState("")

  useEffect(async () => {
    // type
    const resM = await API.get("type_stage/select", {
      lang: lng,
    }).then(resM => {
      setArrayType(resM.data.Type_stage)
    })
    // specialite
    const resL = await APID.get("specialite/select").then(resL => {
      setArraySpec(resL.data.Specialites)
    })
  }, [])

  const changeSpec = e => {
    setSpecialite(e)
    // niveau
    if (e.length > 0) {
      const res = APID.post("niveau/get_by_specialites", {
        specialites: e,
      }).then(res => {
        setArrayNiveau(res.data.Niveaux_By_Specialite)
      })
    } else {
      setArrayNiveau([])
    }
  }

  const save = async () => {
    if (type == "") {
      toast.error("⛔ Sélectioner ou mois un type !", {
        containerId: "A",
      })
    } else if (specialite == "") {
      toast.error("⛔ Sélectioner ou mois une spécialité !", {
        containerId: "A",
      })
    } else if (niveau == "") {
      toast.error("⛔ Sélectioner ou mois une niveau !", {
        containerId: "A",
      })
    } else if (sujet == "" || periode == "" || description == "") {
      toast.error("⛔ Remplir les champs vides !", {
        containerId: "A",
      })
    } else {
      var userAuth = JSON.parse(localStorage.getItem("userAuthStage"))
      var entrepriseId = userAuth.user.id_entreprise
      const res = await API.post("stage/add", {
        lang: lng,
        etat: 0,
        entreprise_id: entrepriseId,
        periode_stage: periode,
        description: description,
        sujet: sujet,
        type_stage: type,
        specialite: specialite,
        niveau: niveau,
      })
        .then(res => {
          props.setSection(1)
        })
        .catch(() => {
          toast.error("⛔ Veuillez vérifier tous les champs !", {
            containerId: "A",
          })
        })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <AvForm>
          <Form>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Sujet :</Label>
                    <AvField
                      name="sujet"
                      placeholder="Entrer sujet"
                      type="text"
                      errorMessage="* Sujet obligatoire"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      onChange={e => setSujet(e.target.value)}
                    />
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Type")}
                  </Label>
                  <Select
                    name="Type"
                    isMulti={true}
                    options={arrayType}
                    isSearchable={true}
                    onChange={e => setType(e)}
                    placeholder={props.t("Select")}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Spécialité")}
                  </Label>
                  <Select
                    name="Spécialité"
                    isMulti={true}
                    options={arraySpec}
                    isSearchable={true}
                    onChange={e => changeSpec(e)}
                    placeholder={props.t("Select")}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Niveau")}
                  </Label>
                  <Select
                    name="Niveau"
                    isMulti={true}
                    options={arrayNiveau}
                    isSearchable={true}
                    onChange={setNiveau}
                    placeholder={props.t("Select")}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1"></Label>
                  <Label for="basicpill-firstname-input1">
                    Période de stage :
                  </Label>
                  <AvField
                    name="Période de stage"
                    placeholder=" Entrer période qui exprimée en mois"
                    type="text"
                    errorMessage="* Période de stage obligatoire"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={e => setPeriode(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1"></Label>
                  <Label for="basicpill-firstname-input1">Description :</Label>
                  <AvField
                    name="description"
                    placeholder="Entrer description"
                    type="text"
                    errorMessage="* Description obligatoire"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={e => setDescription(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </AvForm>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            direction: lng == "ar" ? "initial" : "initial",
          }}
        >
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                name="annuler"
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                {props.t("annuler")}
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button
                name="Confirmer"
                type="button"
                className="btn btn-primary "
                onClick={save}
              >
                {props.t("Confirmer")}
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(AddOffreStage))
AddOffreStage.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
