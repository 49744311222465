import React, { useEffect, useState, useRef, Fragment } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import APIS from "../../apiS"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//i18n
import { withTranslation } from "react-i18next"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"

const DataTableOffreEmplois = props => {
  var lng = localStorage.getItem("I18N_LANGUAGE")
  //
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalClot, setModalClot] = useState(false)
  const [id, setId] = useState("")
  // detail candidature
  const [intitPoste, setIntitPoste] = useState("Développeur laravel")
  const [nbrTotal, setNbrTotal] = useState(13)
  const [nbrRejeter, setNbrRejeter] = useState(4)
  const [nbrAttente, setNbrAttente] = useState(4)
  const [nbrAccepter, setAccepter] = useState(5)
  const [arrayCv, setArrayCv] = useState([])
  // Data Table
  const [offreEmploi, setOffreEmploi] = useState([])
  useEffect(async () => {
    var lang = localStorage.getItem("I18N_LANGUAGE")
    const res = await APIS.post("offre_emploi/list", {
      lang: lang,
    }).then(res => {
      setOffreEmploi(res.data.Emploi)
    })
    setLoading(true)
  }, [])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: offreEmploi.length,
    page: 1,
    nextPageText: props.t("next"),
    prePageText: props.t("back"),
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: offreEmploi.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )

  const { SearchBar } = Search

  const Columns = () => [
    {
      dataField: "date_publication",
      text: props.t("Date de publication"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "intitule_postulation",
      text: props.t("Intitulé de poste"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "type_contrat",
      text: props.t("Type de contrat"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "date_expiration",
      text: props.t("Date d'expiration"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    // {
    //   dataField: "Modification",
    //   isDummyField: true,
    //   text: props.t("Modification"),
    //   style: { textAlign: "center" },
    //   headerStyle: (colum, colIndex) => {
    //     return { textAlign: "center" }
    //   },
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, row) => (
    //     <div style={{ display: "flex", justifyContent: "center" }}>
    //       <Link to={"/EditOffreEmplois?id=" + row.id}>
    //         <i
    //           style={{ color: "cornflowerblue", cursor: "pointer" }}
    //           className="fas fa-edit"
    //         ></i>
    //       </Link>
    //     </div>
    //   ),
    // },
    // {
    //   dataField: "Detail",
    //   isDummyField: true,
    //   text: props.t("Detail postulation"),
    //   style: { textAlign: "center" },
    //   headerStyle: (colum, colIndex) => {
    //     return { textAlign: "center" }
    //   },
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, row) => (
    //     <div
    //       style={{ display: "flex", justifyContent: "center" }}
    //       onClick={() => toggle(row.id)}
    //     >
    //       <i style={{ cursor: "pointer" }} className="fas fa-info-circle"></i>
    //     </div>
    //   ),
    // },
    // {
    //   dataField: "Cloture",
    //   isDummyField: true,
    //   text: props.t("Cloture offre"),
    //   style: { textAlign: "center" },
    //   headerStyle: (colum, colIndex) => {
    //     return { textAlign: "center" }
    //   },
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, row) => (
    //     <div
    //       style={{ display: "flex", justifyContent: "center" }}
    //       onClick={() => toggleCloture(row.id)}
    //     >
    //       <i
    //         style={{ cursor: "pointer", color: "tomato",fontSize:"15px" }}
    //         className="fab fa-expeditedssl"
    //       ></i>
    //     </div>
    //   ),
    // },
  ]

  const toggle = id => {
    setModal(!modal)
    setId(id)
  }

  const closeModal = () => {
    setModal(!modal)
  }

  const toggleCloture = id => {
    setModalClot(!modalClot)
  }

  const closeCloture = () => {
    setModalClot(!modalClot)
  }

  const rowEvent = {
    onClick: async (e, row) => {
      props.history.push("/EditOffreEmplois?id=" + row.id)
    },
  }

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={offreEmploi}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      rowEvents={rowEvent}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {props.t("load_page")}
            </h4>
          </div>
        )}
      </div>
      {/* <> */}
      <Modal isOpen={modal} toggle={closeModal} centered={true}>
        <Fragment>
          <ModalBody>
            <div className="row">
              <div className="col-xl-12">
                <div className="text-center p-4 border-end">
                  <h5 className="text-truncate pb-1">
                  {props.t("Intitulé du poste")} : {intitPoste}
                  </h5>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="row">
                  <div className="col-3">
                    <div>
                      <p
                        style={{ textAlign: "center" }}
                        className="text-muted mb-2 text-truncate"
                      >
                     {props.t("Nombre total")}   
                      </p>
                      <h5 style={{ textAlign: "center" }}>{nbrTotal}</h5>
                    </div>
                  </div>
                  <div className="col-3">
                    <div>
                      <p
                        style={{ textAlign: "center" }}
                        className="text-muted mb-2 text-truncate"
                      >
                         {props.t("Rejeter")} 
                      </p>
                      <h5 style={{ color: "red", textAlign: "center" }}>
                        {nbrRejeter}
                      </h5>
                    </div>
                  </div>
                  <div className="col-3">
                    <div>
                      <p
                        style={{ textAlign: "center" }}
                        className="text-muted mb-2 text-truncate"
                      >
                      {props.t("En attente")} 
                      </p>
                      <h5 style={{ color: "yellow", textAlign: "center" }}>
                        {nbrAttente}
                      </h5>
                    </div>
                  </div>
                  <div className="col-3">
                    <div>
                      <p
                        style={{ textAlign: "center" }}
                        className="text-muted mb-2 text-truncate"
                      >
                         {props.t("Accepter")} 
                      </p>
                      <h5 style={{ color: "green", textAlign: "center" }}>
                        {nbrAccepter}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="mt-12">
                    <div className="card-body">
                      <div className="mb-4 card-title">{props.t("Candidatures")} :</div>
                      <div className="table-responsive">
                        <table className="table table-nowrap align-middle table-hover mb-0 table">
                          <tbody>
                            <tr>
                              <td style={{ width: "45px" }}>
                                <div className="avatar-sm">
                                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24">
                                    <i className="fas fa-file-pdf" />
                                  </span>
                                </div>
                              </td>
                              <td>
                                <h5 className="font-size-14 mb-1">
                                  <a
                                    className="text-dark"
                                    href="/projects-overview"
                                  >
                                    Cv sima
                                  </a>
                                </h5>
                              </td>
                              <td>
                                <div className="text-center">
                                  <i
                                    style={{ cursor: "pointer" }}
                                    className="bx bx-download h3 m-0"
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr></tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Fragment>
      </Modal>
      {/* <> */}
      <Modal isOpen={modalClot} toggle={closeCloture} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            direction: lng == "ar" ? "rtl" : "initial",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
            fontWeight: "bold",
          }}
          toggle={closeCloture}
        >
          {props.t("Mise à jour etat offre")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button className="btn btn-danger" onClick={""}>
                  <i
                    style={{ color: "white", paddingRight: "5px",fontSize:"15px" }}
                    className="fab fa-expeditedssl" 
                  ></i>
                  {props.t("Cloturer")}
                </Button>
              </div>
              <div>
                <Button onClick={closeCloture} className="btn btn-warning">
                  <i
                    style={{ color: "white", paddingRight: "5px" }}
                    className="fas fa-times-circle"
                  ></i>
                  {props.t("Annuler")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableOffreEmplois))
DataTableOffreEmplois.propTypes = {
  offreEmploi: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
