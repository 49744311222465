import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import translationENG from "./locales/eng/translation.json"
import translationAR from "./locales/ar/translation.json"
import translationFR from "./locales/fr/translation.json"
// the translations
const resources = {
  en: {
    translation: translationENG,
  },
  ar: {
    translation: translationAR,
  },
  fr: {
    translation: translationFR,
  },
}
const language = localStorage.getItem("I18N_LANGUAGE")
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "fr")
}
i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "fr",
    fallbackLng: "fr", // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
export default i18n
